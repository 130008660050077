import { selectContentEvent } from '@lno/core/modules/analytics'
import { GASelectEventsPayloads } from '@lno/core/modules/analytics/constants'
import FooterLink from './Link'
import { styles } from './styles'
import { FooterLinkStruct } from './types'

const jsxScope = `jsx-${styles.__hash}`

export const SocialMedia = ({ items }: { items: FooterLinkStruct[] }) => {
  return (
    <div className="footer__social">
      <div className="footer__social-block">
        {items.map((link: FooterLinkStruct, index: number) => {
          const label = `rede-social-${link.name}`
          const handleEvent = () => {
            const payload = {
              ...GASelectEventsPayloads.buttonFooterSocialMedia,
              label,
              itemText: label,
            }
            selectContentEvent(payload)
          }

          return (
            <FooterLink
              className={jsxScope}
              link={link}
              key={index}
              onClick={handleEvent}
            />
          )
        })}
      </div>
      <style jsx>{styles}</style>
    </div>
  )
}
