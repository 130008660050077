import css from 'styled-jsx/css'

export const styles: any = css`
  .navbar {
    display: grid;
    width: 100%;
    align-items: center;
    height: 3rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: column;

    &--fixed {
      grid-template-columns: auto 1fr 1fr;
    }

    &--fixed > :nth-child(2) {
      justify-self: flex-start;
    }
  }
`
