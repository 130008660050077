import css from 'styled-jsx/css'

export const styles: any = css`
  .menu-button {
    display: flex;
    text-decoration: none;
    border-radius: 2px;
    border: 0;
    justify-content: center;
    align-items: center;

    &--primary {
      background-color: var(--theme-primary);
      border-color: var(--theme-primary);
      color: #fff;
    }

    &--white {
      background-color: #fff;
      border-color: #fff;
      color: var(--theme-primary);
    }

    &--small {
      width: 4.3125rem;
      font-size: 0.625rem;
      height: 1.9375rem;
    }

    &--large {
      min-width: 6.875rem;
      font-size: 0.75rem;
      height: 2.125rem;
    }

    &--shadow {
      box-shadow: rgb(18 37 79 / 30%) 0 2px 2px 0;
    }
  }
`
