import css from 'styled-jsx/css'

export const styles: any = css`
  .navbar {
    $root: &;
    width: 100%;
    max-width: var(--container-size);
    margin: 0 auto;
    min-height: 6.8125rem;
    padding: 0.25rem 0;

    &__container {
      display: flex;
      position: relative;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      justify-content: space-between;
      padding-right: 1rem;
      height: 4.25rem;
    }

    &__nav {
      display: flex;
      position: absolute;
      top: 100%;
      align-items: center;
      justify-content: center;
    }

    &__nav-link {
      text-decoration: none;
      color: #fff;
      font-size: 0.875rem;
      padding: 0.625rem;
      margin-right: 5px;
    }

    &--fixed {
      min-height: unset;
      padding: 2px 0;

      #{$root}__container {
        border: none;
        height: 3.25rem;
      }

      #{$root}__nav {
        position: relative;
        top: 0;
        justify-content: flex-end;
        flex: 1;
      }
    }
  }
`
