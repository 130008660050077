import css from 'styled-jsx/css'

export const styles: any = css`
  .divider {
    display: block;
    height: 1.5rem;
    width: 1px;
    background: rgba(255, 255, 255, 0.3);
  }
`
