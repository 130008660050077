import iconFacebookPink from '@assets/images/footer/facebook-pink.svg'
import iconInstagramPink from '@assets/images/footer/instagram-pink.svg'
import iconLinkedinPink from '@assets/images/footer/linkedin-pink.svg'
import iconTiktokPink from '@assets/images/footer/tiktok-pink.svg'
import iconTwitterPink from '@assets/images/footer/twitter-pink.svg'
import iconYoutubePink from '@assets/images/footer/youtube-pink.svg'

import iconPlayStore from '@assets/images/footer/icon-play-store.svg'

import iconAppleStore from '@assets/images/footer/icon-apple-store.svg'
import iconAppleStoreWebp from '@assets/images/footer/icon-apple-store.webp'

import friendJustice from '@assets/images/footer/friend-justice.png'
import friendJusticeWebp from '@assets/images/footer/friend-justice.webp'

import iconGlobal from '@assets/images/footer/global-sign.png'
import iconGlobalWebp from '@assets/images/footer/global-sign.webp'

const links = {
  social: [
    {
      href: '//instagram.com/serasa',
      name: 'instagram',
      external: true,
      img: {
        src: iconInstagramPink,
        alt: 'Navegue para nosso perfil no Instagram, abre nova guia em página externa',
        width: 28,
        height: 28,
      },
    },
    {
      href: '//tiktok.com/@tonaserasa',
      name: 'tiktok',
      external: true,
      img: {
        src: iconTiktokPink,
        alt: 'Navegue para nosso perfil no Tiktok, abre nova guia em página externa',
        width: 28,
        height: 28,
      },
    },
    {
      href: '//youtube.com/serasa',
      name: 'youtube',
      external: true,
      img: {
        src: iconYoutubePink,
        alt: 'Navegue para nosso canal no YouTube, abre nova guia em página externa',
        width: 28,
        height: 28,
      },
    },
    {
      href: '//twitter.com/tonaserasa/',
      name: 'twitter',
      external: true,
      img: {
        src: iconTwitterPink,
        alt: 'Navegue para nosso perfil no Twitter, abre nova guia em página externa',
        width: 28,
        height: 28,
      },
    },
    {
      href: '//facebook.com/serasa.com.br',
      name: 'facebook',
      external: true,
      img: {
        src: iconFacebookPink,
        alt: 'Navegue para nosso perfil no Facebook, abre nova guia em página externa',
        width: 28,
        height: 28,
      },
    },
    {
      href: '//www.linkedin.com/company/serasa',
      name: 'linkedin',
      external: true,
      img: {
        src: iconLinkedinPink,
        alt: 'Navegue para nosso perfil no LinkedIn, abre nova guia em página externa',
        width: 28,
        height: 28,
      },
    },
  ],
  apps: [
    {
      href: '//play.google.com/store/apps/details?id=br.com.serasaexperian.consumidor',
      external: true,
      name: 'Play Store',
      img: {
        src: iconPlayStore,
        alt: 'Baixe nosso app na Play Store, abre nova guia em página externa',
        width: 118,
        height: 36,
      },
    },
    {
      href: '//apps.apple.com/br/app/serasa/id1102452668',
      external: true,
      name: 'Apple Store',
      img: {
        src: iconAppleStore,
        srcWebp: iconAppleStoreWebp,
        alt: 'Baixe nosso app na Apple Store, abre nova guia em página externa',
        width: 106,
        height: 34,
      },
    },
  ],
  otherLinks: [
    {
      title: 'Segurança',
      href: '/politicas-do-site',
    },
    {
      title: 'Termos de Uso e Política de Privacidade',
      href: '/politicas-do-site',
    },
    {
      title: 'Comunicado importante',
      href: 'http://www.procon.sp.gov.br/',
      external: true,
    },
    {
      title: 'Acesso ao Procon',
      href: 'http://www.procon.sp.gov.br/',
      external: true,
    },
    {
      title: 'Órgãos de Defesa do Consumidor',
      href: '/orgaosdedefesadoconsumidor/',
    },
    {
      title: 'Trabalhe com a gente',
      href: '/carreiras',
    },
  ],
}

const addresses = [
  {
    city: 'São Paulo',
    street: 'Av das Nações Unidas, 14.401 - Torre Sucupira - 24º andar',
    neighborhood: 'Chácara Sto. Antônio',
    state: 'SP',
    postalCode: '04794-000',
    cnpj: '62.173.620/0001-80',
    accessibleProps: {
      company: 'Serasa Experian - São Paulo',
      address:
        'Endereço: Avenida das Nações Unidas, número 14.401, Torre Sucupira, vigésimo quarto andar, Chácara Santo Antônio, cidade e estado de São Paulo',
      postalCode: 'CEP: 04794-000',
      cnpj: 'CNPJ: 62-173-620 /0001-80',
    },
  },
  {
    city: 'São Carlos',
    street: 'Av. Dr. Heitor José Reali, 360',
    neighborhood: 'Chácara Sto. Antônio',
    state: 'SP',
    postalCode: '13571-385',
    cnpj: '62.173.620/0093-06',
    accessibleProps: {
      company: 'Serasa Experian - São Carlos',
      address:
        'Endereço: Avenida Doutor Heitor José Reali, número 360, São Carlos, São Paulo',
      postalCode: 'CEP: 13571-385',
      cnpj: 'CNPJ: 62-173-620 /0093-06',
    },
  },
  {
    city: 'Blumenau',
    street: 'Rua Dr. Léo de Carvalho, 74 - Sala 1105',
    neighborhood: 'Bairro Velha',
    state: 'SC',
    postalCode: '89036-239',
    cnpj: '62.173.620/0104-95',
    accessibleProps: {
      company: 'Serasa Experian - Blumenau',
      address:
        'Endereço: Rua Doutor Léo de Carvalho,  número 74, Sala 1105, Bairro Velha, Blumenau, Santa Catarina',
      postalCode: 'CEP: 89036-239',
      cnpj: 'CNPJ: 62-173-620 /0104-95',
    },
  },
  {
    city: 'Brasília',
    street: 'ST SCN, S/N, Qd 02, Bl C, 109 – Sala 301',
    neighborhood: 'Bairro Asa Sul',
    state: 'DF',
    postalCode: '70302-911',
    cnpj: '62.173.620/0131-68',
    accessibleProps: {
      company: 'Serasa Experian - Brasília',
      address:
        'Endereço: Setor Comercial Norte, sem número, entrada 109, Quadra 2, Bloco C, Sala 301, Bairro Asa Sul, Brasília, Distrito Federal',
      postalCode: 'CEP: 70302-911',
      cnpj: 'CNPJ: 62-173-620 /0131-68',
    },
  },
  {
    city: 'Florianópolis',
    street: 'Rod. José Carlos Daux, 8600 – Sala 02',
    neighborhood: 'Bairro Santo Antônio de Lisboa',
    state: 'SC',
    postalCode: '88.050-001',
    cnpj: '62.173.620/0132-49',
    accessibleProps: {
      company: 'Serasa Experian - Florianópolis',
      address:
        'Endereço: Rodovia José Carlos Daux, número 8600 - Sala 02 - Bloco 07 - Bairro Santo Antônio de Lisboa, Florianópolis, Santa Catarina',
      postalCode: 'CEP: 88.050-001',
      cnpj: 'CNPJ: 62.173.620/0132-49',
    },
  },
  {
    city: 'Recife',
    street:
      'Rua Senador José Henrique, 231 - Edifício Empresarial Charles Darwin - 2º andar',
    neighborhood: 'Bairro Ilha do Leite',
    state: 'PE',
    postalCode: '50070-460',
    cnpj: '62.173.620/0133-20',
    accessibleProps: {
      company: 'Serasa Experian - Recife',
      address:
        'Endereço: Edifício Empresarial Charles Darwin - 2º andar - Rua Senador José Henrique, número 231 - Bairro Ilha do Leite, Recife, Pernambuco',
      postalCode: 'CEP: 50070-460',
      cnpj: 'CNPJ: 62.173.620/0133-20',
    },
  },
]

export {
  addresses,
  friendJustice,
  friendJusticeWebp,
  iconGlobal,
  iconGlobalWebp,
  links
}

