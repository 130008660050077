import clsx, { ClassValue } from 'clsx'

type CssValue = number | string | null | undefined | boolean

export const sanitizeCssProp = (property: string, value: CssValue) => {
  return value || value === 0 ? `${property}: ${value};` : ``
}

export const scopedClsx = (styles: { __hash: string }) => {
  const scope = `jsx-${styles.__hash}`
  return (...names: ClassValue[]) => clsx(scope, ...names)
}

export { clsx }
