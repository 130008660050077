import { breakpoints } from '@lno/constants/breakpoints'
import { Viewport } from '@lno/core/constants/viewport'

import { ViewportPayload } from './types'

type SubscribeFn = (mql: ViewportPayload) => void

function MediaQueryObserver() {
  const handlers = new Set<SubscribeFn>()
  let mediaQueryDesktop: MediaQueryList
  let mediaQueryTablet: MediaQueryList

  function notify(fn: SubscribeFn) {
    const isDesktop = mediaQueryDesktop.matches
    const isTablet = mediaQueryTablet.matches
    const isMobile = !isTablet && !isDesktop

    let key = Viewport.Mobile

    if (isDesktop) {
      key = Viewport.Desktop
    } else if (isTablet) {
      key = Viewport.Tablet
    }

    const payload = {
      isDesktop,
      isTablet,
      isMobile,
      key,
    }

    fn(payload)
  }

  function notifyAll() {
    handlers.forEach((fn) => notify(fn))
  }

  function attachListeners() {
    mediaQueryDesktop = window.matchMedia(breakpoints.desktop)
    mediaQueryTablet = window.matchMedia(breakpoints.tabletOnly)

    mediaQueryDesktop.addListener(notifyAll)
    mediaQueryTablet.addListener(notifyAll)
  }

  function removeListener() {
    mediaQueryDesktop.removeListener(notifyAll)
    mediaQueryTablet.removeListener(notifyAll)
  }

  function unsubscribe(fn: SubscribeFn) {
    if (handlers.size === 1) removeListener()

    handlers.delete(fn)
  }

  function subscribe(fn: SubscribeFn) {
    if (handlers.size === 0) attachListeners()

    handlers.add(fn)
    notify(fn)

    return () => unsubscribe(fn)
  }

  return {
    subscribe,
    unsubscribe,
  }
}

const instance = MediaQueryObserver()

export { instance as MediaQueryObserver }
