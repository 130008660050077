import clsx from 'clsx'

import { Link } from '@lno/components/Link'

import {
  EVENT_PRODUCT,
  EventAction,
  EventArea,
  EventCategory,
  EventContentType,
} from '@lno/core/modules/analytics/constants'
import { selectContentEvent } from '@lno/core/modules/analytics/utils/selectContentEvent'
import { styles } from './styles'

const jsxScope = `jsx-${styles.__hash}`

export function FooterProductsLinks() {
  const handleClickEvent = () => {
    selectContentEvent({
      category: EventCategory.LnoHome,
      itemId: `${EVENT_PRODUCT}_web_link_rodape_01`,
      contentType: EventContentType.Link,
      action: EventAction.LinkClick,
      label: 'desenrola-brasil',
      area: EventArea.Home,
      itemText: 'desenrola-brasil',
    })
  }

  return (
    <div className="products-links">
      <div className="products-links__map">
        <div className="products-links__column">
          <ul className="products-links__list">
            <li>
              <p className="products-links__link-head">Serasa Crédito</p>
            </li>
            <li>
              <a
                href="/credito/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="credito"
                data-gtm-name="pedir-emprestimo-online"
              >
                Pedir empréstimo on-line
              </a>
            </li>
            <li>
              <a
                href="/consultar-meu-cpf/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ecred"
                data-gtm-name="consultar-meu-cpf"
              >
                Consultar meu CPF
              </a>
            </li>
            <li>
              <a
                href="/ecred/simulador/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ecred"
                data-gtm-name="simulador"
                aria-label="Simulador de crédito e empréstimo"
              >
                Simulador
              </a>
            </li>
            <li>
              <a
                href="/ecred/como-funciona-credito-on-line/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ecred"
                data-gtm-name="como-funciona-credito-on-line"
              >
                Como funciona o crédito
              </a>
            </li>
            <li>
              <a
                href="/ecred/cartao-de-credito/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ecred"
                data-gtm-name="cartao-de-credito/"
                aria-label="Cartão de crédito online"
              >
                Cartão de crédito
              </a>
            </li>
            <li>
              <a
                href="/ecred/cartao-de-credito-consignado/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ecred"
                data-gtm-name="cartao-de-credito-consignado/"
              >
                Cartão de crédito consignado
              </a>
            </li>
            <li>
              <a
                href="/ecred/cartao-pre-pago/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ecred"
                data-gtm-name="cartao-pre-pago/"
              >
                Cartão pré-pago
              </a>
            </li>
            <li>
              <a
                href="/ecred/emprestimo-pessoal/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ecred"
                data-gtm-name="emprestimo-pessoal"
                aria-label="Empréstimo pessoal online"
              >
                Empréstimo pessoal
              </a>
            </li>
            <li>
              <a
                href="/ecred/emprestimo-para-negativado/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ecred"
                data-gtm-name="emprestimo-para-negativado"
              >
                Empréstimo para negativado
              </a>
            </li>
            <li>
              <a
                href="/ecred/emprestimo-com-garantia-de-imovel/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ecred"
                data-gtm-name="emprestimo-com-garantia-de-imovel"
                aria-label="Empréstimo com garantia de imóvel"
              >
                Empréstimo com garantia
              </a>
            </li>
            <li>
              <a
                href="/ecred/emprestimo-para-autonomo/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ecred"
                data-gtm-name="emprestimo-para-autonomo"
              >
                Empréstimo para autônomo
              </a>
            </li>
            <li>
              <a
                href="/ecred/emprestimo-para-autonomo-negativado/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ecred"
                data-gtm-name="emprestimo-para-autonomo-negativado"
                aria-label="Empréstimo para negativado autônomo"
              >
                Autônomo negativado
              </a>
            </li>
            <li>
              <a
                href="/ecred/emprestimo-sem-comprovacao-de-renda/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ecred"
                data-gtm-name="emprestimo-sem-comprovacao-de-renda"
                aria-label="Empréstimo sem comprovação de renda"
              >
                Sem comprovação de renda
              </a>
            </li>
            <li>
              <a
                href="/ecred/por-que-meu-credito-foi-negado/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ecred"
                data-gtm-name="por-que-meu-credito-foi-negado"
              >
                Por que meu crédito foi negado?
              </a>
            </li>
            <li>
              <a
                href="/ecred/como-funciona-o-ecred/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ecred"
                data-gtm-name="como-funciona-o-ecred"
              >
                Como funciona o Serasa Crédito?
              </a>
            </li>
            <li>
              <a
                href="/ecred/faq/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ecred"
                data-gtm-name="ecred-faq"
              >
                Tire suas dúvidas
              </a>
            </li>
          </ul>
          <ul className="products-links__list">
            <li>
              <p className="products-links__link-head">Carteira Digital</p>
            </li>
            <li>
              <a
                href="/carteira-digital/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="carteira-digital"
                data-gtm-name="serasa-carteira-digital"
              >
                Sua nova Carteira Digital
              </a>
            </li>
          </ul>
        </div>
        <div className="products-links__column">
          <ul className="products-links__list">
            <li>
              <p className="products-links__link-head">Limpa Nome</p>
            </li>
            <li>
              <a
                href="/limpa-nome-online/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="limpa-nome"
                data-gtm-name="limpar-meu-nome"
              >
                Limpar meu nome
              </a>
            </li>
            <li>
              <a
                href="/limpa-nome-online/parceiros/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="limpa-nome"
                data-gtm-name="parceiros"
                aria-label="Serasa Parceiros"
              >
                Parceiros
              </a>
            </li>
            <li>
              <a
                href="/limpa-nome-online/seguranca/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="limpa-nome"
                data-gtm-name="seguranca"
              >
                Segurança & Dúvidas
              </a>
            </li>
            <li>
              <a
                href="/ensina/seu-nome-limpo/como-limpar-o-nome-na-serasa/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="limpa-nome"
                data-gtm-name="como-limpar-o-nome-na-serasa"
              >
                Limpar o nome em 4 passos
              </a>
            </li>
            <li>
              <a
                href="/ensina/seu-nome-limpo/divida-com-bancos/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="limpa-nome"
                data-gtm-name="divida-com-bancos"
                aria-label="Como negociar dívida com bancos"
              >
                Como negociar dívida com bancos
              </a>
            </li>
            <li>
              <a
                href="/ensina/seu-nome-limpo/divida-no-cnpj/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="limpa-nome"
                data-gtm-name="divida-no-cnpj"
              >
                Dívida no CNPJ
              </a>
            </li>
            <li>
              <a
                href="/ensina/seu-nome-limpo/saiba-como-manter-o-nome-limpo/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="limpa-nome"
                data-gtm-name="saiba-como-manter-o-nome-limpo"
              >
                Como manter o nome limpo
              </a>
            </li>
            <li>
              <a
                href="/ensina/seu-nome-limpo/saiba-quais-fatores-podem-sujar-o-nome/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="limpa-nome"
                data-gtm-name="saiba-quais-fatores-podem-sujar-o-nome"
              >
                Quais fatores podem sujar o nome
              </a>
            </li>
          </ul>
          <br />
          <ul className="products-links__list">
            <li>
              <p className="products-links__link-head">Cadastro Positivo</p>
            </li>
            <li>
              <a
                href="/cadastro-positivo/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="cadastro-positivo"
                data-gtm-name="serasa-cadastro-positivo"
              >
                Serasa Cadastro Positivo
              </a>
            </li>
            <li>
              <a
                href="/cadastro-positivo/faq"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="cadastro-positivo"
                data-gtm-name="cadastro-positivo-faq"
              >
                Dúvidas sobre Cadastro Positivo
              </a>
            </li>
            <li>
              <a
                href="/ensina/aumentar-score/cadastro-positivo-como-funciona/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="cadastro-positivo"
                data-gtm-name="cadastro-positivo-como-funciona"
              >
                Como funciona o Cadastro Positivo
              </a>
            </li>
          </ul>
          <br />
          <ul className="products-links__list">
            <li>
              <p
                className="products-links__link-head"
                aria-label="Serasa Você Consulta"
              >
                Você Consulta
              </p>
            </li>
            <li>
              <a
                href="/voceconsulta/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="voceconsulta"
                data-gtm-name="serasa-voce-consulta"
              >
                Serasa Você Consulta
              </a>
            </li>
          </ul>
        </div>
        <div className="products-links__column">
          <ul className="products-links__list">
            <li>
              <p className="products-links__link-head">Serasa Premium</p>
            </li>
            <li>
              <a
                href="/premium/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="premium"
                data-gtm-name="monitorar-meu-cpf"
              >
                Monitorar meu CPF
              </a>
            </li>
            <li>
              <a
                href="/premium/monitoramento-cpf/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="premium"
                data-gtm-name="monitoramento-cpf"
              >
                Monitoramento de CPF
              </a>
            </li>
            <li>
              <a
                href="/premium/monitoramento-score/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="premium"
                data-gtm-name="monitoramento-score"
              >
                Monitoramento de Score
              </a>
            </li>
            <li>
              <a
                href="/premium/consulta-cpf/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="premium"
                data-gtm-name="consulta-cpf"
              >
                Consulta CPF
              </a>
            </li>
            <li>
              <a
                href="/premium/darkweb/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="premium"
                data-gtm-name="darkweb"
              >
                Vazamento da Dark Web
              </a>
            </li>
            <li>
              <a
                href="/premium/monitoramento-cnpj/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="premium"
                data-gtm-name="monitoramento-cnpj"
              >
                Monitoramento CNPJ
              </a>
            </li>
            <li>
              <a
                href="/premium/consulta-cnpj/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="premium"
                data-gtm-name="consulta-cnpj"
              >
                Consulta CNPJ
              </a>
            </li>
          </ul>
          <br />
          <ul className="products-links__list">
            <li>
              <p className="products-links__link-head">Score</p>
            </li>
            <li>
              <a
                href="/score/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="score"
                data-gtm-name="consultar-pontuacao-do-score"
              >
                Consultar pontuação do Score
              </a>
            </li>
            <li>
              <a
                href="/ensina/dicas/mentiras-sobre-a-serasa/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="score"
                data-gtm-name="mentiras-sobre-a-serasa"
                aria-label="10 maiores mentiras sobre a Serasa que circulam por aí"
              >
                Mitos
              </a>
            </li>
            <li>
              <a
                href="/ensina/aumentar-score/12-dicas-para-aumentar-o-serasa-score/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="score"
                data-gtm-name="12-dicas-para-aumentar-o-serasa-score"
                aria-label="Dicas para aumentar o Serasa Score"
              >
                Aumentar o Serasa Score
              </a>
            </li>
            <li>
              <a
                href="/ensina/aumentar-score/antecedencia-ajuda-no-score/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="score"
                data-gtm-name="antecedencia-ajuda-no-score"
              >
                Antecedência ajuda no Score
              </a>
            </li>
            <li>
              <a
                href="/ensina/aumentar-score/score-sobe-de-quanto-em-quanto-tempo/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="score"
                data-gtm-name="score-sobe-de-quanto-em-quanto-tempo"
              >
                Score sobe de quanto em quanto tempo?
              </a>
            </li>
            <li>
              <a
                href="/ensina/aumentar-score/pontuacao-cai-ao-fazer-consulta-score/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="score"
                data-gtm-name="pontuacao-cai-ao-fazer-consulta-score"
              >
                Pontuação cai ao fazer consulta?
              </a>
            </li>
            <li>
              <a
                href="/ensina/aumentar-score/como-aumentar-seu-score-de-credito/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="score"
                data-gtm-name="como-aumentar-seu-score-de-credito"
              >
                Como aumentar seu score
              </a>
            </li>
          </ul>
        </div>
        <div className="products-links__column">
          <ul className="products-links__list">
            <li>
              <p className="products-links__link-head">Ensina</p>
            </li>
            <li>
              <a
                href="/ensina/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ensina"
                data-gtm-name="ensserasa-ensina"
                aria-label="Descomplique sua vida financeira! - Serasa Ensina"
              >
                Serasa Ensina
              </a>
            </li>
            <li>
              <a
                href="/ensina/consultar-cpf-e-score/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ensina"
                data-gtm-name="consultar-cpf-e-score"
              >
                Consultar CPF e Score
              </a>
            </li>
            <li>
              <a
                href="/ensina/suas-economias/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ensina"
                data-gtm-name="suas-economias"
              >
                Como Economizar Dinheiro
              </a>
            </li>
            <li>
              <a
                href="/ensina/como-ganhar-dinheiro/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ensina"
                data-gtm-name="como-ganhar-dinheiro"
              >
                Como Ganhar Dinheiro
              </a>
            </li>
            <li>
              <a
                href="/ensina/dicas/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ensina"
                data-gtm-name="dicas"
              >
                Dicas Financeiras
              </a>
            </li>
            <li>
              <a
                href="/ensina/seu-credito/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ensina"
                data-gtm-name="seu-credito"
              >
                Conseguir Crédito
              </a>
            </li>
            <li>
              <a
                href="/ensina/seu-nome-limpo/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ensina"
                data-gtm-name="seu-nome-limpo"
              >
                Seu Nome Limpo
              </a>
            </li>
            <li>
              <a
                href="/ensina/seu-cpf-protegido/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ensina"
                data-gtm-name="seu-cpf-protegido"
                aria-label="Seu CPF Protegido"
              >
                CPF Protegido
              </a>
            </li>
            <li>
              <a
                href="/ensina/aumentar-score/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ensina"
                data-gtm-name="aumentar-score"
                aria-label="Como Aumentar Score"
              >
                Aumentar Score
              </a>
            </li>
            <li>
              <a
                href="/ensina/dicas/cadastro-unico/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ensina"
                data-gtm-name="cadastro-unico"
              >
                Cadastro Único
              </a>
            </li>
            <li>
              <a
                href="/ensina/como-ganhar-dinheiro/auxilio-emergencial/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ensina"
                data-gtm-name="auxilio-emergencial"
                aria-label="Auxílio emergencial e outros benefícios da quarentena - Serasa Ensina"
              >
                Auxílio emergencial
              </a>
            </li>
            <li>
              <a
                href="/ensina/consultar-cpf-e-score/como-consultar-o-cpf-pelo-nome/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ensina"
                data-gtm-name="como-consultar-o-cpf-pelo-nome"
                aria-label="Como consultar o CPF pelo nome?"
              >
                Como consultar o CPF
              </a>
            </li>
            <li>
              <a
                href="/ensina/consultar-cpf-e-score/como-consultar-o-rg-pelo-cpf/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ensina"
                data-gtm-name="como-consultar-o-rg-pelo-cpf"
                aria-label="Passo a passo de como consultar o RG pelo CPF"
              >
                Consultar o RG com CPF
              </a>
            </li>
            <li>
              <a
                href="/ensina/consultar-cpf-e-score/como-consultar-score-na-serasa/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ensina"
                data-gtm-name="como-consultar-score-na-serasa"
                aria-label="Como consultar o Score na Serasa"
              >
                Como consultar o Score
              </a>
            </li>
            <li>
              <a
                href="/ensina/consultar-cpf-e-score/consultar-seu-cpf-na-serasa-online-e-gratis/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ensina"
                data-gtm-name="consultar-seu-cpf-na-serasa-online-e-gratis"
                aria-label="Consultar seu CPF na Serasa online e grátis!"
              >
                Consultar seu CPF grátis!
              </a>
            </li>
            <li>
              <a
                href="/ensina/consultar-cpf-e-score/golpe-com-empresa-de-casamento/"
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="ensina"
                data-gtm-name="golpe-com-empresa-de-casamento"
                aria-label="Golpe Com Empresa de Casamento"
              >
                Golpe no Casamento
              </a>
            </li>
          </ul>
          <ul className="products-links__list">
            <li>
              <p className="products-links__link-head">Seja parceiro</p>
            </li>
            <li>
              <a
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="parceiro"
                data-gtm-name="seja-parceiro-serasa"
                aria-label="Seja parceiro Serasa"
                href="/seja-parceiro/"
              >
                Seja parceiro Serasa
              </a>
            </li>
            <li>
              <a
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="parceiro"
                data-gtm-name="seja-parceiro-serasa-limpa-nome"
                aria-label="Seja parceiro Serasa Limpa Nome"
                href="/limpa-nome-online/seja-parceiro/"
              >
                Seja parceiro Serasa Limpa Nome
              </a>
            </li>
            <li>
              <a
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="parceiro"
                data-gtm-name="seja-parceiro-ecred"
                aria-label="Seja parceiro Serasa eCred"
                href="/ecred/seja-parceiro/"
              >
                Seja parceiro Serasa Crédito
              </a>
            </li>
          </ul>
        </div>
        <div className="products-links__column">
          <ul className="products-links__list">
            <li>
              <p className="products-links__link-head">O que você precisa?</p>
            </li>
            <li>
              <a
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="precisa"
                data-gtm-name="desenrola-brasil"
                href="https://www.serasa.com.br/limpa-nome-online/desenrola-brasil/"
                onClick={handleClickEvent}
                rel="noreferrer"
              >
                Desenrola Brasil
              </a>
            </li>
            <li>
              <a
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="precisa"
                data-gtm-name="falar-com-a-serasa"
                href="/atendimento/"
              >
                Falar com a Serasa
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="precisa"
                data-gtm-name="atualizacao_de_cadastro"
                href="https://www.serasa.com.br/outros-servicos/atualizacao_de_cadastro/"
              >
                Atualizar meus dados
              </a>
            </li>
            <li>
              <Link
                className={clsx(jsxScope, 'products-links__link')}
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="precisa"
                data-gtm-name="solucoes-para-empresas"
                href="//www.serasaexperian.com.br"
                external
              >
                Soluções para empresas
              </Link>
            </li>
            <li>
              <a
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="precisa"
                data-gtm-name="solucoes-para-empresas"
                href="/dados/revisar-dado-negativo"
              >
                Revisão de dado negativo
              </a>
            </li>
          </ul>
          <br />
          <ul className="products-links__list">
            <li>
              <p className="products-links__link-head">Atendimento</p>
            </li>
            <li>
              <a
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="atendimento"
                data-gtm-name="agencias"
                href="/sc-atendimento/agencias/"
              >
                Pontos de Atendimento
              </a>
            </li>
            <li>
              <a
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="atendimento"
                data-gtm-name="telefones-atendimento"
                href="/atendimento"
              >
                Telefones
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="atendimento"
                data-gtm-name="ajuda-serasa"
                href="https://ajuda.serasa.com.br/"
              >
                Central de Ajuda
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="atendimento"
                data-gtm-name="denuncie-uma-fraude-digital"
                href="https://www.serasa.com.br/contra-fraudes/"
              >
                Denuncie uma fraude digital
              </a>
            </li>
          </ul>
          <br />
          <ul className="products-links__list">
            <li>
              <p className="products-links__link-head">Outros Serviços</p>
            </li>
            <li>
              <a
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="servicos"
                data-gtm-name="consultar-meu-cpf"
                href="/consultar-meu-cpf/"
              >
                Consultar meu CPF
              </a>
            </li>
            <li>
              <a
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="servicos"
                data-gtm-name="consultar-seu-cpf"
                href="/consultar-meu-cpf/"
              >
                Como consultar seu CPF
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="servicos"
                data-gtm-name="perdi-meus-documentos-e-agora"
                href="https://ajuda.serasa.com.br/hc/pt-br/articles/360012555551-Perdi-meus-documentos-e-agora-?"
              >
                Proteger meus documentos
              </a>
            </li>
            <li>
              <a
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="servicos"
                data-gtm-name="carta-serasa-experian"
                href="/carta-serasa-experian/"
              >
                Carta comunicado
              </a>
            </li>
            <li>
              <a
                target="_blank"
                className="products-links__link"
                data-gtm-type="click"
                data-gtm-clicktype="rodape"
                data-gtm-subname="servicos"
                data-gtm-name="consultas-ao-meu-cpf"
                href="/consultas-ao-meu-cpf/"
              >
                Empresas que consultam meu CPF
              </a>
            </li>
          </ul>
        </div>
      </div>

      <style jsx>{styles}</style>
    </div>
  )
}

export default FooterProductsLinks
