import css from 'styled-jsx/css'

import { breakpoints } from '@lno/constants'

export const styles: any = css`
  .what-you-need-menu {
    $root: &;
    border: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    position: relative;

    &__divider {
      display: none;
    }

    &__toggle-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      text-align: left;
      padding: 0.375rem 1rem;
      height: 3.4375rem;
      color: #666666;
      font-size: 0.875rem;
    }

    &__toggle-icon {
      transition: transform 0.2s ease-in-out 0s;
    }

    &__signin {
      display: none;
    }

    &__list-wrapper {
      display: flex;
      flex-direction: column;
    }

    &__list {
      display: none;
      list-style: none;
      position: relative;
      margin: 0.25rem 0px 2.5rem;

      &::after {
        content: '';
        position: absolute;
        width: calc(100% - 2.75rem);
        left: 1.375rem;
        height: 1px;
        bottom: -1.25rem;
        background-color: #e0e0e0;
      }
    }

    &__link {
      margin-top: 5px;
      display: block;
      text-decoration: none;
      font-size: 0.875rem;
      color: #666666;
      padding: 0.375rem 1rem;
    }

    &__list-icon {
      transform: rotate(-90deg);
      margin-right: 0.5rem;
      fill: currentColor;
    }

    &--open {
      #{$root}__list-wrapper {
        padding-bottom: 1rem;
      }

      #{$root}__list {
        display: block;
      }

      #{$root}__toggle-icon {
        transform: rotate(-180deg);
      }
    }

    @media ${breakpoints.desktop} {
      flex-direction: row;
      border-color: transparent;
      align-items: center;

      &__divider {
        display: block;
      }

      &__toggle-button {
        color: #fff;
      }

      &__toggle-icon {
        margin-left: 0.325rem;
      }

      &__signin {
        display: flex;
        flex-shrink: 0;
        font-size: 0.75rem;
        width: 4.8125rem;
        height: 2.125rem;
        margin-left: 1rem;
      }

      &__list {
        margin-bottom: 0;
        border-right: 1px solid #dadada;

        &::after {
          display: none;
        }
      }

      &__link {
        margin-top: 0.625rem;
      }

      #{$root}__list-wrapper {
        display: none;
        flex-direction: row;
        position: absolute;
        top: 100%;
        width: 33.5rem;
        left: 50%;
        transform: translateX(-75%);
        padding: 2rem 1rem;
        background-color: #fff;
        overflow: hidden;
        border-radius: 3px;
        flex-wrap: wrap;
        box-shadow: rgb(0 0 0 / 10%) 0 2px 0.25rem 0;
      }

      &--open {
        #{$root}__list-wrapper {
          display: flex;
        }

        #{$root}__toggle-button {
          background-color: #fff;
          color: var(--theme-primary);
        }
      }

      &--fixed {
        #{$root}__toggle-button,
        #{$root}__divider:first-child {
          display: none;
        }
      }
    }
  }
`
