import clsx from 'clsx'

import { FooterLink } from './Link'
import { FooterProductsLinks } from './ProductsLinks'

import {
  addresses,
  friendJustice,
  friendJusticeWebp,
  iconGlobal,
  iconGlobalWebp,
  links,
} from './constants'
import { FooterLinkStruct } from './types'

import { Typography } from '@lno/components/Typography'
import { selectContentEvent } from '@lno/core/modules/analytics'
import { GASelectEventsPayloads } from '@lno/core/modules/analytics/constants'
import { Address } from './Address'
import { SocialMedia } from './SocialMedia'
import { StoreApps } from './StoreApps'
import { styles } from './styles'

const jsxScope = `jsx-${styles.__hash}`

type FooterProps = {
  hasAnchorBanner: boolean
}

export const renderLinks = (links: FooterLinkStruct[]) =>
  links.map((link: FooterLinkStruct, index: number) => {
    return <FooterLink className={jsxScope} link={link} key={index} />
  })

export function Footer(props: FooterProps) {
  const { hasAnchorBanner } = props

  const label = 'amiga-da-justica-parceiro-institucional'
  const handleSeloEvent = () => {
    const payload = {
      ...GASelectEventsPayloads.seloFooter,
      label,
      itemText: label,
    }
    selectContentEvent(payload)
  }

  const footerStyle = clsx('footer', {
    'footer--with-banner': hasAnchorBanner,
  })

  const linkFriendJustice =
    'https://www.serasa.com.br/imprensa/serasa-se-torna-parceira-institucional-do-programa-empresa-amiga-da-justica/'
  return (
    <footer className={footerStyle}>
      <div className="footer__container">
        <FooterProductsLinks />

        <div className="footer__external">
          <SocialMedia items={links.social} />
          <StoreApps apps={links.apps} />
          <div className="footer-stamp">
            <a
              href={linkFriendJustice}
              onClick={handleSeloEvent}
              target="_blank"
              rel="noreferrer"
            >
              <picture>
                <source srcSet={`${friendJusticeWebp} 1x`} type="image/webp" />
                <img
                  src={friendJustice}
                  loading="lazy"
                  alt="selo amiga da justiça"
                  width="60"
                />
              </picture>
            </a>

            <picture>
              <source srcSet={`${iconGlobalWebp} 1x`} type="image/webp" />
              <img
                src={iconGlobal}
                loading="lazy"
                alt="Serasa Experian"
                width="110"
                height="45"
              />
            </picture>
          </div>
        </div>

        <div className="footer__other-links">
          {renderLinks(links.otherLinks)}
        </div>

        <div className="footer__address">
          <Typography
            size="bodyP"
            tag="h4"
            className={clsx(jsxScope, 'footer__address-title')}
            color="high"
          >
            Endereços da Serasa (sem atendimento ao público):
          </Typography>

          <ul>
            {addresses.map((address, index) => (
              <Address key={index} {...address} />
            ))}
          </ul>
        </div>
      </div>
      <style jsx>{styles}</style>
    </footer>
  )
}

export default Footer
