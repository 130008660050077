import { BASE_URL, LN_WEBFILES_COMMON_PRODUCTS_URL } from '@lno/core/constants'

export const NAVBAR_FIXED_OFFSET = 120

export const MENUS = [
  {
    key: 'home',
    url: `${BASE_URL}`,
    label: 'Home',
  },
  {
    key: 'partners',
    url: `${BASE_URL}/parceiros`,
    label: 'Parceiros',
  },
  {
    key: 'blog',
    url: `${BASE_URL}/blog`,
    label: 'Blog',
  },
  {
    key: 'security',
    url: `${BASE_URL}/seguranca`,
    label: 'Segurança',
  },
  {
    key: 'faq',
    url: `${BASE_URL}/faq`,
    label: 'FAQ',
    hiddenComplement: ' Perguntas frequentes',
  },
  {
    key: 'boleto',
    url: `${BASE_URL}/boleto`,
    label: 'Como gerar boleto',
  },
  {
    key: 'feirao',
    url: `${BASE_URL}/feirao`,
    label: 'Feirão',
    hiddenComplement: ' Limpa Nome',
  },
]

export const WHAT_DO_YOU_NEED = [
  { title: 'Consultar meu CPF', href: '/consultar-meu-cpf/' },
  { title: 'Pedir empréstimo on-line', href: '/' },
  { title: 'Consultar pontuação do Score', href: '/score/' },
  { title: 'Monitorar meu CPF', href: '/premium/monitoramento-cpf/' },
  { title: 'Limpar meu nome', href: '/limpa-nome-online/' },
  { title: 'Negociar minhas dívidas', href: '/limpa-nome-online/' },
  { title: 'Falar com a Serasa', href: '/atendimento/' },
  { title: 'Atualizar meus dados', href: '/meus-dados/alterar-dados/' },
  {
    title: 'Soluções para empresas',
    href: '//www.serasaexperian.com.br/',
    external: true,
  },
  { title: 'Proteção de Dados Pessoais', href: '/protecao-dados-pessoais/' },
]

export const PRODUCTS = [
  {
    title: 'Serasa Crédito',
    href: '/credito/',
    src: `${LN_WEBFILES_COMMON_PRODUCTS_URL}/square/credito.svg`,
  },
  {
    title: 'Serasa Premium',
    href: '/premium/',
    src: `${LN_WEBFILES_COMMON_PRODUCTS_URL}/square/serasa-premium.svg`,
  },
  {
    title: 'Serasa Limpa Nome',
    href: '/limpa-nome-online/',
    src: `${LN_WEBFILES_COMMON_PRODUCTS_URL}/square/limpa-nome.svg`,
  },
  {
    title: 'Serasa Score',
    href: '/score/',
    src: `${LN_WEBFILES_COMMON_PRODUCTS_URL}/square/score.svg`,
  },
  {
    title: 'Serasa Cadastro Positivo',
    href: '/cadastro-positivo/',
    src: `${LN_WEBFILES_COMMON_PRODUCTS_URL}/square/cadastro-positivo.svg`,
  },
  {
    title: 'Serasa Ensina',
    href: '/ensina/',
    src: `${LN_WEBFILES_COMMON_PRODUCTS_URL}/square/ensina.svg`,
  },
  {
    title: 'Você Consulta ',
    href: '/voce-consulta/',
    src: `${LN_WEBFILES_COMMON_PRODUCTS_URL}/square/vcep.svg`,
  },
]
