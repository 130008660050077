import clsx from 'clsx'

import { styles } from './styles'
import { PropsWithChildren } from 'react'

type VisuallyHiddenProps = PropsWithChildren<{
  className?: string
  tag?: 'span' | 'div' | 'p'
}>

export function VisuallyHidden(props: VisuallyHiddenProps) {
  const { tag, className } = props
  const Component = tag!

  return (
    <>
      <Component {...props} className={clsx(className, 'sr-only')} />
      <style jsx>{styles}</style>
    </>
  )
}

VisuallyHidden.defaultProps = {
  tag: 'span',
}
