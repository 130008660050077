import clsx from 'clsx'
import { ReactElement, useState } from 'react'

import { Collapse } from '@lno/components/Collapse'
import { Typography } from '@lno/components/Typography'

import { GASelectContentEvent } from '@lno/core/modules/analytics/events'
import { globalStyles, styles } from './styles'

type CollapsibleSectionProps = {
  id?: string
  title: string
  content: string | ReactElement
  bold?: boolean
}

export const CollapsibleSection = ({
  id,
  title,
  content,
  bold,
}: CollapsibleSectionProps) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
    if (open) return GASelectContentEvent.closeFAQQuestion(title)
    return GASelectContentEvent.openFAQQuestion(title)
  }

  const toggleButtonClassName = clsx('collapsible-section__toggle', {
    'collapsible-section__toggle--open': open,
    'collapsible-section__toggle--bold': bold,
  })

  return (
    <div
      className="collapsible-section"
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
    >
      <button
        className={toggleButtonClassName}
        type="button"
        itemProp="name"
        onClick={handleClick}
        aria-expanded={open}
        aria-controls={id}
      >
        {title}
      </button>

      <Collapse
        id={id}
        open={open}
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
      >
        <div className="collapsible-section__wrapper">
          <Typography
            size="bodyM"
            align="left"
            color="high"
            itemProp="text"
            tag="div"
            className="collapsible-section__info__span"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </Collapse>
      <style jsx global>
        {globalStyles}
      </style>
      <style jsx>{styles}</style>
    </div>
  )
}

export default CollapsibleSection
