import { useState, useEffect, useRef, KeyboardEvent } from 'react'
import clsx from 'clsx'

import { SERASA_LOGIN_URL } from '@lno/constants'
import { useClickAwayListener } from '@lno/hooks/useClickAwayListener'
import { useEscapeKeyPress } from '@lno/hooks/useEscapeKeyPress'
import { Link } from '@lno/components/Link'

import { ReactComponent as ChevronSvg } from '@assets/images/chevron-bottom.svg'

import { isTabKeyEvent } from '@lno/utils/keyboard'

import { WHAT_DO_YOU_NEED } from '../constants'

import { ProductsMenu } from '../ProductsMenu'
import { Button } from '../Button'
import { Divider } from '../Divider'

import { styles } from './styles'

const jsxScope = `jsx-${styles.__hash}`

type WhatYouNeedMenuProps = {
  isNavFixed?: boolean
  signInEnabled?: boolean
}

export function WhatYouNeedMenu({
  isNavFixed,
  signInEnabled,
}: WhatYouNeedMenuProps) {
  const [open, setOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLUListElement>(null)

  const handleToggle = () => {
    setOpen((prevState) => !prevState)
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (open && isTabKeyEvent(event)) {
      event.preventDefault()

      const firstLink = menuRef.current!.querySelector('a')

      firstLink?.focus()
    }
  }

  const closeMenu = () => setOpen(false)

  const containerStyles = clsx('what-you-need-menu', {
    'what-you-need-menu--open': open,
    'what-you-need-menu--fixed': isNavFixed,
  })

  useClickAwayListener<HTMLDivElement>(menuRef, closeMenu, {
    enabled: open,
  })

  useEscapeKeyPress(closeMenu, { enabled: open })

  useEffect(() => {
    if (isNavFixed && open) {
      closeMenu()
    }
  }, [isNavFixed, open])

  return (
    <>
      <div className={containerStyles}>
        <Divider className={clsx(jsxScope, 'what-you-need-menu__divider')} />

        <button
          type="button"
          onClick={handleToggle}
          onKeyDown={handleKeyDown}
          className="what-you-need-menu__toggle-button"
          aria-haspopup="true"
          aria-expanded={open}
          aria-controls="navbar-what-you-need-menu-list"
        >
          O que você precisa?
          <ChevronSvg
            className={clsx(jsxScope, 'what-you-need-menu__toggle-icon')}
          />
        </button>

        {signInEnabled && (
          <>
            <Divider
              className={clsx(jsxScope, 'what-you-need-menu__divider')}
            />

            <Button
              href={SERASA_LOGIN_URL}
              color="white"
              size="small"
              className={clsx(jsxScope, 'what-you-need-menu__signin')}
            >
              ENTRAR
            </Button>
          </>
        )}

        <div ref={menuRef} className="what-you-need-menu__list-wrapper">
          <ul
            id="navbar-what-you-need-menu-list"
            className="what-you-need-menu__list"
            ref={listRef}
          >
            {WHAT_DO_YOU_NEED.map((link, index) => (
              <li key={index} className="what-you-need-menu__list-item">
                <Link
                  href={link.href}
                  className={clsx(jsxScope, 'what-you-need-menu__link')}
                  external={link.external}
                >
                  <ChevronSvg
                    className={clsx(jsxScope, 'what-you-need-menu__list-icon')}
                  />
                  {link.title}
                </Link>
              </li>
            ))}
          </ul>
          <ProductsMenu open={open} />
        </div>
      </div>

      <style jsx>{styles}</style>
    </>
  )
}

WhatYouNeedMenu.defaultProps = {}

export default WhatYouNeedMenu
