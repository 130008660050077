export const KEYBOARD_KEY_EVENT = {
  TAB: {
    key: 'Tab',
    code: 9,
  },
  ESCAPE: {
    key: 'Escape',
    code: 27,
  },
}
