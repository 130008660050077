import clsx from 'clsx'

import { styles } from './styles'

type ButtonProps = {
  color: 'primary' | 'white'
  size: 'small' | 'large'
  href: string
  className?: string
  shadow?: boolean
  [k: string]: unknown
}

export function Button(props: ButtonProps) {
  const { color, size, shadow, className, ...otherProps } = props

  const buttonStyles = clsx(className, 'menu-button', {
    ['menu-button--' + color]: color,
    ['menu-button--' + size]: size,
    ['menu-button--shadow']: shadow,
  })

  return (
    <>
      <a className={buttonStyles} {...otherProps} />
      <style jsx>{styles}</style>
    </>
  )
}

export default Button
