import css from 'styled-jsx/css'

import { breakpoints } from '@lno/constants'

export const styles: any = css`
  .footer {
    position: relative;
    font-size: 1rem;
    background-color: #ffffff;
  }
  .footer-stamp {
    display: flex;
    gap: 2rem;
    align-items: center;
  }

  .footer a {
    color: #575756;
    font-size: 1rem;
    text-decoration: none;
    transition: color, opacity 0.2s ease-out;
    will-change: color, opacity;
  }

  .footer::before,
  .footer::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    background-image: linear-gradient(
      to top,
      var(--theme-primary),
      var(--theme-primary)
    );
    height: 0.25rem;
  }

  .footer::before {
    top: 0;
  }

  .footer::after {
    bottom: 0;
  }

  .footer--with-banner {
    padding-bottom: 4rem;
  }

  .footer__container {
    max-width: 120rem;
    margin: auto;
    padding: 0 1rem;
  }

  .footer__external {
    width: 100%;
    position: relative;
    padding: 2rem 0;
    gap: 1.5rem;
    border-bottom: 1px solid #c4c4c4;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .footer__social {
    width: 100%;
  }

  .footer__apps {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .footer__apps a {
    outline-offset: 2px;
  }

  .footer__social-block {
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: space-between;

    & a {
      outline-offset: 2px;

      &:last-child {
        margin-right: 2.5rem;
      }
    }
  }

  .footer__other-links {
    display: flex;
    align-content: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem;
    border-bottom: 1px solid #c4c4c4;
    box-sizing: border-box;
    flex-wrap: wrap;
  }

  .footer__other-links a {
    color: var(--theme-primary);
    border-bottom: 1px solid currentColor;
    text-decoration: none;
    font-size: 0.875rem;
    margin: 6px 0 1rem 0;
    text-align: center;
    width: calc(50% - 0.75rem);
  }

  .footer__address {
    margin-top: 2.5rem;
    ul {
      margin-left: 0;
    }
  }

  .footer__address-li {
    color: #575756;
    list-style: none;
    margin-bottom: 0.5rem;
  }

  .footer__address p:last-child {
    margin-bottom: 0;
  }

  @media ${breakpoints.mediumMobile} {
    .footer__address-title {
      margin-bottom: 0.8rem;
    }
  }

  @media ${breakpoints.mediumMobile} {
    .footer__social-block {
      justify-content: flex-start;
      gap: 2rem;
    }
  }
  @media ${breakpoints.desktop} {
    .footer {
      padding-top: 2.5rem;
    }

    .footer__container {
      padding: 0 3rem;
    }

    .footer__external {
      flex-direction: row;
      align-items: center;
    }

    .footer__social {
      max-width: 18.75rem;
    }

    .footer__other-links {
      justify-content: space-around;
      padding: 1.875em 0;
    }

    .footer__other-links a {
      width: auto;
    }

    .footer__address-title {
      margin-bottom: 0.5rem;
    }
  }
`
