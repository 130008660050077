import clsx from 'clsx'

import { SERASA_LOGIN_URL } from '@lno/constants'

import { profileImg } from '../images'

import { styles } from './styles'

type ProfileMenuProps = {
  isNavFixed: boolean
  className: string
}

export function ProfileMenu(props: ProfileMenuProps) {
  const { isNavFixed, className } = props

  const containerStyles = clsx(className, 'profile-menu', {
    'profile-menu--fixed': isNavFixed,
  })

  return (
    <>
      <div className={containerStyles}>
        <a
          href={SERASA_LOGIN_URL}
          aria-label="Entrar no Serasa Limpa Nome"
          className="profile-menu__avatar-link"
        >
          <img src={profileImg} alt="" width="22" height="22" />
        </a>
        <a
          href={SERASA_LOGIN_URL}
          aria-label="Entrar no Serasa Limpa Nome"
          className="profile-menu__button"
        >
          ENTRAR
        </a>
      </div>

      <style jsx>{styles}</style>
    </>
  )
}
