import clsx from 'clsx'

import { Brand } from '../Brand'
import { WhatYouNeedMenu } from '../WhatYouNeedMenu'

import { MENUS } from '../constants'

import { styles } from './styles'
import { AnchorTestButton } from '../AnchorTestButton'
import { VisuallyHidden } from '../../VisuallyHidden'

type DesktopNavProps = {
  isNavFixed: boolean
  signInEnabled?: boolean
  web?: boolean
}

export function DesktopNav({
  isNavFixed,
  signInEnabled,
  web,
}: DesktopNavProps) {
  const navbarStyles = clsx('navbar', {
    'navbar--fixed': isNavFixed,
  })

  return (
    <>
      <header className={navbarStyles}>
        <div className="navbar__container">
          <Brand isNavFixed={isNavFixed} web={web} />

          <nav className="navbar__nav">
            {MENUS.map((menu) => (
              <a key={menu.key} href={menu.url} className="navbar__nav-link">
                {menu.label}
                {menu.hiddenComplement && (
                  <VisuallyHidden>{menu.hiddenComplement}</VisuallyHidden>
                )}
              </a>
            ))}
            {isNavFixed && (
              <AnchorTestButton
                showIcon={true}
                className="anchor__button-header"
              />
            )}
          </nav>

          <WhatYouNeedMenu
            isNavFixed={isNavFixed}
            signInEnabled={signInEnabled}
          />
        </div>
      </header>

      <style jsx>{styles}</style>
    </>
  )
}
