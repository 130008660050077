import { useEffect, useState } from 'react'

import { pageViewEvent, PageViewEventPayload } from '../utils/pageViewEvent'

type PageViewOptions = {
  skipFirstRender?: boolean
}

export function usePageViewEffect(
  payload: PageViewEventPayload,
  options: PageViewOptions = {}
) {
  const { skipFirstRender = true } = options
  const [isFirstRender, setFirstRender] = useState(true)

  useEffect(() => {
    setFirstRender(false)
  }, [skipFirstRender])

  useEffect(() => {
    if (isFirstRender && skipFirstRender) {
      return
    }

    if (!isFirstRender && !skipFirstRender) {
      return
    }

    pageViewEvent(payload)
  }, [payload, isFirstRender, skipFirstRender])
}
