import { useState, useEffect } from 'react'
import clsx from 'clsx'

import { Button } from '../Button'
import { WhatYouNeedMenu } from '../WhatYouNeedMenu'
import { ReactComponent as CloseIconSvg } from '@assets/images/close.svg'

import { SERASA_LOGIN_URL } from '@lno/constants'

import { arrowRightImg } from '../images'
import { MENUS } from '../constants'

import { styles } from './styles'

const jsxScope = `jsx-${styles.__hash}`

type PopUpMenuProps = {
  open: boolean
  signInEnabled?: boolean
  onClose(): void
}

export function PopUpMenu(props: PopUpMenuProps) {
  const { open, signInEnabled, onClose } = props
  const [mounted, setMounted] = useState(false)

  const containerStyles = clsx('popup-menu', {
    'popup-menu--open': open,
  })

  useEffect(() => {
    if (open && !mounted) {
      setMounted(true)
    }
  }, [open, mounted])

  if (!mounted) return null

  return (
    <>
      <div className={containerStyles}>
        <button
          onClick={onClose}
          aria-label="Fechar menu"
          className="popup-menu__close-button"
        >
          <CloseIconSvg className={clsx(jsxScope, 'popup-menu__close-icon')} />{' '}
          <span>FECHAR</span>
        </button>

        {signInEnabled && (
          <div className="popup-menu__button-wrapper">
            <Button
              href={SERASA_LOGIN_URL}
              color="white"
              size="large"
              className={jsxScope}
            >
              CADASTRE-SE
            </Button>

            <Button
              href={SERASA_LOGIN_URL}
              color="primary"
              size="large"
              className={jsxScope}
            >
              ENTRAR
            </Button>
          </div>
        )}

        <nav className="popup-menu__list">
          {MENUS.map((menu) => (
            <a key={menu.key} href={menu.url} className="popup-menu__link">
              {menu.label}
              <img
                src={arrowRightImg}
                alt=""
                className="popup-menu__list-icon"
              />
            </a>
          ))}
        </nav>

        <WhatYouNeedMenu />
      </div>

      <style jsx>{styles}</style>
    </>
  )
}

export default PopUpMenu
