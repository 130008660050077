import css from 'styled-jsx/css'

import { breakpoints } from '@lno/constants'

export const styles: any = css`
  .products-links {
    width: 100%;
  }

  .products-links__map {
    display: inline;
    flex-direction: row;
    margin: 0;
  }

  .products-links__map br {
    display: none;
  }

  .products-links__column {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .products-links__list {
    list-style: none;
    margin-bottom: 0.5rem;
  }

  .products-links__list li {
    margin: 0;
  }

  .products-links__link-head {
    font-size: 1.3rem !important;
    color: #575756 !important;
    text-decoration: none !important;
    letter-spacing: 0.3px;
    margin: 0 0.7em 0.5em;
    line-height: 3rem;
  }

  .products-links__link {
    font-size: 0.9rem !important;
    margin: 0 1.2em 0.5em;
    line-height: 3rem;
    text-decoration: none !important;
    letter-spacing: 0.3px;
    color: #575756;
  }

  @media ${breakpoints.tablet} {
    .products-links__column {
      display: inline-flex;
      width: 30%;
      margin-right: 1.3em;
    }

    .products-links__list {
      list-style: none;
      margin-bottom: 0.5rem;
    }

    .products-links__list li {
      margin: 0;
    }

    .products-links__link-head {
      font-size: 1.3rem !important;
      color: #575756 !important;
      text-decoration: none !important;
      letter-spacing: 0.3px;
      margin: 0 0 0.5em;
      line-height: 3rem;
    }

    .products-links__link {
      font-size: 0.9rem !important;
      margin: 0 0 0.5em;
      line-height: 1.4rem;
      text-decoration: none !important;
      letter-spacing: 0.3px;
    }
  }

  @media ${breakpoints.desktop} {
    .products-links__map br {
      display: initial;
    }

    .products-links__column {
      display: inline-flex;
      width: 18% !important;
      margin-right: 1.3em;
    }

    .products-links__link {
      display: block;
      margin: 0 0 0.5em;
      line-height: normal;
    }

    .products-links__link-head {
      display: block;
      letter-spacing: 0.3px;
      margin: 0 0 0.5em;
      line-height: normal;
    }
  }

  @media ${breakpoints.bigDesktop} {
    .products-links__map {
      display: flex;
    }
  }
`
