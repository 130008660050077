import clsx from 'clsx'

import { styles } from './styles'

type SpinnerProps = {
  visible: boolean
  className?: string
}

export function Spinner({ visible, className }: SpinnerProps) {
  const finalClassName = clsx(className, 'spinner', {
    'spinner--visible': visible,
  })

  return (
    <>
      <div role="progressbar" className={finalClassName}>
        <span className={'spinner__sr-message'}>Carregando...</span>
      </div>

      <style jsx>{styles}</style>
    </>
  )
}

Spinner.defaultProps = {
  className: null,
}
