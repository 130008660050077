import { useEffect, useState } from 'react'

import { ViewportPayload } from './types'

import { MediaQueryObserver } from './observer'

export function useViewport(): ViewportPayload {
  const [media, setMedia] = useState<ViewportPayload>({
    isDesktop: false,
    isMobile: false,
    isTablet: false,
    key: null,
  })

  useEffect(() => {
    const unsubscribe = MediaQueryObserver.subscribe(setMedia)

    return () => unsubscribe()
  }, [])

  return media
}

export default useViewport
