import clsx from 'clsx'
import Lazy from 'react-lazyload'

import { Button } from '@lno/components/Button'
import { Typography } from '@lno/components/Typography'

import { LN_WEBFILES_URL } from '@lno/constants'

import { GASelectContentEvent } from '@lno/core/modules/analytics/events'
import { getLoginUrl } from '@lno/core/utils/login'
import { styles } from './styles'

const jsxScope = `jsx-${styles.__hash}`

type BoxCtaLoginProps = {
  customText?: string
  customButtonLabel?: string
  className?: string
  partnerKey?: string
  blackLabelGAEvent?(): void
  web?: boolean
  isFeiraoLno?: boolean
}

export function BoxCtaLogin({
  partnerKey,
  customText,
  customButtonLabel,
  className,
  blackLabelGAEvent,
  web,
  isFeiraoLno,
}: BoxCtaLoginProps) {
  const defaultText = isFeiraoLno ? (
    <>
      Consulte ofertas para
      <br />
      suas dívidas
    </>
  ) : (
    'Consulte grátis as ofertas disponíveis para suas dívidas.'
  )

  const defaultButtonLabel = 'Consultar CPF'

  const handleClick = () => {
    if (blackLabelGAEvent) return blackLabelGAEvent()
    return GASelectContentEvent.checkDiscounts()
  }

  return (
    <div className={clsx(className, 'box-cta-login')}>
      <Typography
        tag="h2"
        size={isFeiraoLno ? 'headingS' : 'headingXS'}
        color="high"
        align={isFeiraoLno ? 'center' : 'left'}
      >
        {customText || defaultText}
      </Typography>

      <Button
        tag="a"
        role="button"
        href={getLoginUrl(partnerKey, web)}
        onClick={handleClick}
        color="accent"
        className={clsx(jsxScope, 'box-cta-login__cta')}
        aria-label={defaultButtonLabel}
      >
        {customButtonLabel || defaultButtonLabel}
      </Button>

      {!isFeiraoLno && (
        <div className="box-cta-login__footer" aria-hidden>
          <Typography
            tag="p"
            size="bodyP"
            color="high"
            className={clsx(jsxScope, 'box-cta-login__footer-text')}
          >
            Um produto
          </Typography>

          <Lazy height={32} once style={{ width: 68, height: 32 }}>
            <img
              src={`${LN_WEBFILES_URL}/logo/serasa.svg`}
              alt="Logo da Serasa"
              className="box-cta-login__serasa-logo"
              width="68"
              height="32"
            />
          </Lazy>
        </div>
      )}

      <style jsx>{styles}</style>
    </div>
  )
}

BoxCtaLogin.defaultProps = {
  className: null,
  customText: null,
}

export default BoxCtaLogin
