export function checkPassiveEventSupport() {
  let supportsPassive = false

  try {
    const opts = Object.defineProperty({}, 'passive', {
      get: function () {
        supportsPassive = true
      },
    })

    //@ts-expect-error
    window.addEventListener('testPassive', null, opts)
    //@ts-expect-error
    window.removeEventListener('testPassive', null, opts)
  } catch (e) { }

  return supportsPassive
}

export const addPassiveEventListener = (
  element: typeof window,
  type: 'scroll' | 'resize',
  handler: (ev: Event | UIEvent) => any
) => {
  const opts = checkPassiveEventSupport() ? { passive: true } : false

  element.addEventListener(type, handler, opts)
}
