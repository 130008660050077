import css from 'styled-jsx/css'

import { breakpoints } from '@lno/constants'

export const styles: any = css`
  .products-menu {
    display: none;
    margin: 0 0.5rem;

    &__list {
      list-style: none;
    }

    &__label {
      display: none;
    }

    &__link {
      display: flex;
      align-items: center;
      padding: 0.375rem 1rem;
      text-decoration: none;
      font-size: 0.875rem;
      color: rgb(102, 102, 102);

      & + & {
        margin-top: 5px;
      }
    }

    &__icon {
      margin-right: 0.5rem;
    }

    &--open {
      display: block;
    }

    @media ${breakpoints.desktop} {
      flex-direction: column;
      margin: 0.625rem 0.5rem;

      &__label {
        display: block;
        font-weight: 500;
        padding: 0px 1rem;
        font-size: 0.875rem;
        color: #666666;
        margin-bottom: 0.375rem;
      }

      &--open {
        display: flex;
      }
    }
  }
`
