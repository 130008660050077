import {
  EVENT_PRODUCT,
  EventAction,
  EventArea,
  EventCategory,
  EventContentType,
  EventEnviroment,
  EventType,
} from '../constants'

declare global {
  interface Window {
    dataLayer: any[]
  }
}

type DataLayerEvent = {
  event: EventType
  vertical_produto: typeof EVENT_PRODUCT
  tipo_projeto?: string
  ambiente: EventEnviroment
  area: EventArea
  categoria?: EventCategory
  acao?: EventAction | string
  rotulo?: string
  user_id?: string
  method?: string
  page_location?: string
  page_title?: string
  page_path?: string
  item_text?: string
  content_type?: EventContentType
  item_id?: string
  descricao?: string
  parceiro?: string
}

export const dataLayerPush = (...args: DataLayerEvent[]) => {
  if (process.browser) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(...args)
  }
}
