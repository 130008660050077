import { createContext, ReactNode, useEffect, useState } from 'react'
import {
  getLastCampaignRequest,
  getPartnersRequest,
} from '@lno/core/api/builder'
import type { Campaign, Partners } from '@lno/core/models/Campaign'

export const CampaignProvider = ({
  partners: partnersProp,
  campaign: campaignProp,
  children,
}: CampaignProviderProps) => {
  const [partners, setPartners] = useState<Partners | null>(partnersProp)
  const [campaign, setCampaign] = useState<Campaign | null>(campaignProp)

  const isActiveCampaign = !!partners && !!campaign

  const reloadCampaign = async () => {
    const currentDate = Date.now()
    const [campaign, partners] = await Promise.all([
      getLastCampaignRequest(currentDate),
      getPartnersRequest(currentDate),
    ])
    setCampaign(campaign)
    setPartners(partners)
  }

  useEffect(() => {
    if (!isActiveCampaign) return

    const interval = setInterval(() => {
      if (campaign?.endDate && campaign.endDate < Date.now())
        return reloadCampaign()
    }, 10000)

    return () => clearInterval(interval)
  }, [campaign, isActiveCampaign])

  return (
    <CampaignContext.Provider
      value={{
        partners,
        campaign,
        isActiveCampaign,
      }}
    >
      {children}
    </CampaignContext.Provider>
  )
}

interface CampaignContextProps {
  campaign: Campaign | null
  partners: Partners | null
  isActiveCampaign: boolean
}

const defaultCampaignContext = {
  campaign: null,
  partners: null,
  isActiveCampaign: false,
}

export const CampaignContext = createContext<CampaignContextProps>(
  defaultCampaignContext
)

interface CampaignProviderProps {
  partners: Partners | null
  campaign: Campaign | null
  children: ReactNode
}
