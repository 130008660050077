/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, MouseEvent, RefObject } from 'react'

import useEventCallback from '../useEventCallback'

type UseClickAwayListenerOptions<T> = {
  containerRef?: RefObject<T>
  enabled?: boolean
}

export function useClickAwayListener<T extends Node>(
  elementRef: RefObject<T>,
  onClick: (event?: MouseEvent) => void,
  options: UseClickAwayListenerOptions<T> = {}
) {
  const { containerRef, enabled = true } = options

  const handleClickOutside = useEventCallback(
    (event: MouseEvent) => {
      const isOutsideClick =
        elementRef.current && !elementRef.current.contains(event.target as Node)

      if (isOutsideClick) {
        onClick(event)
      }
    },
    [onClick]
  )

  useEffect(() => {
    const container = containerRef?.current || document

    if (enabled) {
      container.addEventListener('click', handleClickOutside)
    } else {
      container.removeEventListener('click', handleClickOutside)
    }

    return () => {
      container.removeEventListener('click', handleClickOutside)
    }
  }, [enabled])
}
