import clsx from 'clsx'
import { forwardRef } from 'react'

import {
  TypographyAlign,
  TypographyColor,
  TypographyRef,
  TypographySize,
  TypographyTag,
} from './types'

import { styles } from './styles'

type BaseTypography = {
  tag: TypographyTag
  size: TypographySize
  color?: TypographyColor
  align?: TypographyAlign
  weight?: 'bold' | 'normal'
  underline?: boolean
  italic?: boolean
  className?: string
}

type BaseTypographyWithIndexes = BaseTypography & {
  [k: string]: unknown
}

type TypographyProps = BaseTypography | BaseTypographyWithIndexes

export const Typography = forwardRef<TypographyRef, TypographyProps>(
  (props, ref) => {
    const {
      tag: Comp,
      className,
      align,
      size,
      italic,
      color,
      weight,
      underline,
      ...otherProps
    } = props

    const finalClassNames = clsx(className, 'typo', `typo--${size}`, {
      'typo--underline': underline,
      'typo--italic': italic,
      [`typo--${weight}`]: weight,
      [`typo--${align}`]: align,
      [`typo--${color}`]: color,
    })

    return (
      <>
        <Comp ref={ref as any} className={finalClassNames} {...otherProps} />
        <style jsx>{styles}</style>
      </>
    )
  }
)

Typography.displayName = 'Typography'

Typography.defaultProps = {
  tag: 'span',
  color: 'high',
}

export default Typography
