import clsx from 'clsx'
import Typography from '../../Typography'

import { styles } from '../styles'
import { VisuallyHidden } from '../../VisuallyHidden'

const jsxScope = `jsx-${styles.__hash}`

type AddressProps = {
  city: string
  street: string
  neighborhood: string
  state: string
  postalCode: string
  cnpj: string
  accessibleProps: {
    company: string
    address: string
    postalCode: string
    cnpj: string
  }
}

export const Address = ({ accessibleProps, ...props }: AddressProps) => {
  return (
    <Typography
      size="caption"
      tag="li"
      className={clsx(jsxScope, 'footer__address-li')}
      color="low"
      itemProp="address"
      itemScope
      itemType="https://schema.org/PostalAddress"
    >
      <AccessibleAddressLayout {...accessibleProps} />
      <DefaultAddressLayout {...props} />
    </Typography>
  )
}

const AccessibleAddressLayout = ({
  address,
  postalCode,
  cnpj,
  company,
}: AddressProps['accessibleProps']) => {
  return (
    <VisuallyHidden tag="div">
      <VisuallyHidden tag="p">{company}</VisuallyHidden>
      <VisuallyHidden tag="p">{address}</VisuallyHidden>
      <VisuallyHidden tag="p">{postalCode}</VisuallyHidden>
      <VisuallyHidden tag="p">{cnpj}</VisuallyHidden>
    </VisuallyHidden>
  )
}

const DefaultAddressLayout = ({
  city,
  street,
  neighborhood,
  state,
  postalCode,
  cnpj,
}: Omit<AddressProps, 'accessibleProps'>) => {
  return (
    <span aria-hidden>
      © Serasa Experian - <span itemProp="addressLocality">{city}</span> -
      <span itemProp="streetAddress">{street}</span> - {neighborhood} - {city} -
      <span itemProp="addressRegion">{state}</span> - CEP{' '}
      <span itemProp="postalCode">{postalCode}</span> - CNPJ {cnpj}
    </span>
  )
}
