import clsx from 'clsx'

import { PRODUCTS } from '../constants'

import { styles } from './styles'

type ProductsMenuProps = {
  open: boolean
}

export function ProductsMenu(props: ProductsMenuProps) {
  const { open } = props

  const containerStyles = clsx('products-menu', {
    'products-menu--open': open,
  })

  return (
    <>
      <div className={containerStyles}>
        <span className="products-menu__label">Produtos</span>

        <ul className="products-menu__list">
          {PRODUCTS.map((link, index) => (
            <li key={index}>
              <a href={link.href} className="products-menu__link">
                <img
                  src={link.src}
                  width="32"
                  height="32"
                  alt=""
                  className="products-menu__icon"
                  loading="lazy"
                />
                {link.title}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <style jsx>{styles}</style>
    </>
  )
}

export default ProductsMenu
