export const getLinkProps = ({
  external,
  target,
}: {
  external?: boolean
  target?: string
}) => {
  if (external) {
    const nextTarget = target || '_blank'
    const isNewWindow = nextTarget === '_blank'
    const context = isNewWindow ? 'nova janela ' : ''
    const message = `, abre ${context}em site externo.`
    const rel = isNewWindow ? 'noreferrer' : undefined

    return {
      rel,
      target: nextTarget,
      message,
    }
  }

  return {}
}
