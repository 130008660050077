export const throttle = <T extends any[]>(
  callback: (..._: T) => void,
  wait: number = 300
): ((..._: T) => void) => {
  let timeoutRef: NodeJS.Timeout | undefined
  let previouslyRun: number

  return function invokeFn(...args: T) {
    // @ts-expect-error
    const context = this
    const now = Date.now()

    timeoutRef = clearTimeout(timeoutRef!) as undefined

    if (!previouslyRun || now - previouslyRun >= wait) {
      callback(...args)
      previouslyRun = now
    } else {
      timeoutRef = setTimeout(
        () => invokeFn.apply(context, args),
        wait - (now - previouslyRun)
      )
    }
  }
}
