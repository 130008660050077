import css from 'styled-jsx/css'

export const styles: any = css`
  .anchor__button {
    background-color: #fff;
    padding: 0.4rem;
    border-radius: 10px;
    min-width: 12rem;
    text-decoration: none;
    color: #e63888;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &-icon {
      position: absolute;
      left: 8px;
      top: 7px;
    }

    &-header {
      display: none;
    }
  }
  .large {
    min-width: 100%;
  }
  .medium {
    min-width: 14rem;
    min-height: 3rem;

    img {
      width: 24px;
      height: 24px;
      top: 13px;
    }
  }
  .small {
    min-width: 8.2px;
  }
`
