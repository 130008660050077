import { KeyboardEvent } from 'react'

import { KEYBOARD_KEY_EVENT } from '@lno/core/constants/keyboard'

export function isTabKeyEvent(event: KeyboardEvent) {
  const key = event.key || event.keyCode || event.which

  return (
    key === KEYBOARD_KEY_EVENT.TAB.key || key === KEYBOARD_KEY_EVENT.TAB.code
  )
}

export function isEscapeKeyEvent(event: KeyboardEvent) {
  const key = event.key || event.keyCode || event.which

  return (
    key === KEYBOARD_KEY_EVENT.ESCAPE.key ||
    key === KEYBOARD_KEY_EVENT.ESCAPE.code
  )
}

export function isShiftKeyEvent(event: KeyboardEvent) {
  return event.shiftKey
}
