import {
  useRef,
  cloneElement,
  useEffect,
  useState,
  ReactElement,
  useCallback,
} from 'react'
import clsx from 'clsx'

import {
  updateCssProp,
  getScrollHeight,
  triggerReflow,
  removeCssProp,
} from './utils'

type CollapseProps = {
  open: boolean
  children: ReactElement
  duration?: number
  tag?: 'div' | 'p' | 'section'
  className?: string
  id?: string
  labelledBy?: string
  [k: string]: unknown
}

export function Collapse(props: CollapseProps): ReactElement {
  const {
    open,
    children,
    className,
    id,
    labelledBy,
    duration,
    tag: Comp = 'div',
    ...otherProps
  } = props

  const childrenRef = useRef<HTMLElement>(null)
  const collapseRef = useRef<HTMLDivElement>(null)
  const [transitionEnabled, setTransitionEnabled] = useState(false)
  const isMounted = useRef(false)

  const handleTransitionEnd = () => {
    if (open) {
      removeCssProp(collapseRef.current, 'max-height')
    } else {
      updateCssProp(collapseRef.current, 'visibility', 'hidden')
    }
  }

  const initialize = useCallback((open: boolean) => {
    if (!open) {
      updateCssProp(collapseRef.current, 'maxHeight', '0')
      updateCssProp(collapseRef.current, 'visibility', 'hidden')
    }

    setTransitionEnabled(true)
    isMounted.current = true
  }, [])

  const collapse = () => {
    updateCssProp(
      collapseRef.current,
      'maxHeight',
      getScrollHeight(childrenRef.current)
    )

    triggerReflow(childrenRef.current)
    updateCssProp(collapseRef.current, 'maxHeight', '0')
  }

  const expand = () => {
    const height = getScrollHeight(childrenRef.current)

    removeCssProp(collapseRef.current, 'visibility')
    updateCssProp(collapseRef.current, 'maxHeight', height)
  }

  useEffect(() => {
    if (!isMounted.current) return

    if (open) {
      expand()
    } else {
      collapse()
    }
  }, [open])

  useEffect(() => {
    if (isMounted.current) return

    initialize(open)
  }, [initialize, open])

  if (!Comp) throw new Error('Invalid component tag')

  return (
    <Comp
      id={id}
      aria-labelledby={labelledBy}
      ref={collapseRef}
      onTransitionEnd={handleTransitionEnd}
      className={clsx(className, 'collapse')}
      {...otherProps}
    >
      {cloneElement(children, {
        ref: childrenRef,
      })}

      <style jsx>
        {`
          .collapse {
            overflow: hidden;
            will-change: max-height;
          }
        `}
      </style>

      <style jsx>
        {`
          .collapse {
            transition: ${transitionEnabled
              ? `max-height ${duration}ms ease`
              : 'none'};
          }
        `}
      </style>
    </Comp>
  )
}

Collapse.defaultProps = {
  duration: 250,
}

export default Collapse
