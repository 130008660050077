import { MouseEvent, ReactNode } from 'react'

import { Button } from '@lno/core/components/Button'
import { Link } from '@lno/core/components/Link'

import {
  ButtonColor,
  ButtonVariant,
  ButtonWeight,
} from '@lno/core/components/Button/types'

type ButtonLinkProps = {
  href: string
  target?: '_blank'
  rel?: 'noreferrer'
  role?: 'button' | 'link'
  color: ButtonColor
  variant?: ButtonVariant
  weight?: ButtonWeight
  children: ReactNode
  className?: string
  external?: boolean
  onClick?(event: MouseEvent): void
  tabIndex?: number
}

export function ButtonLink(props: ButtonLinkProps) {
  return <Button {...props} tag={Link} />
}

export default ButtonLink
