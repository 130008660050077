import clsx from 'clsx'

import { styles } from './styles'
import { getLoginUrl } from '@lno/core/utils/login'
import { checkCpf } from '../images'

type AnchorTestButtonProps = {
  showIcon: boolean
  size?: string
  className?: string
}

export const AnchorTestButton = ({
  showIcon,
  size,
  className,
}: AnchorTestButtonProps) => (
  <>
    <a
      role="button"
      href={getLoginUrl()}
      className={clsx('anchor__button', size, className)}
    >
      {showIcon && (
        <img
          src={checkCpf}
          alt="Consultar CPF"
          width="20"
          height="20"
          className="anchor__button-icon"
        />
      )}
      <span className="anchor__test-text">Consultar CPF</span>
    </a>

    <style jsx>{styles}</style>
  </>
)
