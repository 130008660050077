import clsx from 'clsx'

import { styles } from './styles'

type DividerProps = {
  className?: string
}

export function Divider({ className }: DividerProps) {
  return (
    <>
      <span className={clsx(className, 'divider')} />
      <style jsx>{styles}</style>
    </>
  )
}

export default Divider
