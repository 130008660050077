import { ReactNode, MouseEvent, forwardRef } from 'react'
import clsx from 'clsx'

import { Spinner } from '@lno/components/Spinner'
import { Link } from '@lno/core/components/Link'

import { ButtonColor, ButtonVariant, ButtonWeight } from './types'

import { styles } from './styles'

type Tag = 'button' | 'a' | typeof Link

type CommonProps = {
  color: ButtonColor
  variant?: ButtonVariant
  weight?: ButtonWeight
  children: ReactNode
  className?: string
  fullWidth?: boolean
  role?: string
  onClick?(event?: MouseEvent): void
  disabled?: boolean
  loading?: boolean
}

type ButtonTagProps =
  | {
      tag?: Extract<Tag, 'button'>
      type?: 'button' | 'submit'
      href?: never
    }
  | {
      tag: Exclude<Tag, 'button'>
      href: string
      type?: never
    }

type ButtonProps = ButtonTagProps & CommonProps

const jsxScope = `jsx-${styles.__hash}`

export const Button = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>((props: ButtonProps, ref) => {
  const {
    variant,
    color,
    weight,
    children,
    tag,
    type,
    loading,
    onClick,
    className,
    fullWidth,
    ...otherProps
  } = props
  const Comp = tag as Tag
  const finalType = tag === 'button' ? type || 'button' : undefined

  const finalClassName = clsx(
    className,
    jsxScope,
    'button',
    'button--' + variant,
    {
      [`button--${color}`]: color !== 'none',
      [`button--bold`]: weight === 'bold',
      [`button--full-width`]: fullWidth,
    }
  )

  const handleClick = (event: MouseEvent) => {
    if (onClick) onClick(event)
  }

  return (
    <Comp
      ref={ref as any}
      type={finalType}
      onClick={handleClick}
      className={finalClassName}
      {...otherProps}
    >
      {loading ? <Spinner visible /> : children}

      <style jsx>{styles}</style>
    </Comp>
  )
})

Button.defaultProps = {
  variant: 'raised',
  weight: 'bold',
  tag: 'button',
  fullWidth: false,
}

export default Button
