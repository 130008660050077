import { SERASA_LOGIN_URL, SERASA_LOGIN_URL_WEB } from '../constants'

export const getLoginUrl = (partnerKey?: string, web?: boolean) => {
  if (web) {
    return SERASA_LOGIN_URL_WEB
  } else if (partnerKey) {
    return `${SERASA_LOGIN_URL}/parceiro/${partnerKey}`
  } else {
    return SERASA_LOGIN_URL
  }
}
