import { CampaignContext } from '@lno/screens/Home/providers/CampaignProvider'
import { useContext } from 'react'

export function useCampaign() {
  const { campaign, isActiveCampaign } = useContext(CampaignContext)

  return {
    campaign,
    isActiveCampaign,
  }
}
