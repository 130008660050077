import css from 'styled-jsx/css'

import { breakpoints } from '@lno/constants'

export const styles: any = css`
  .nav-brand {
    $root: &;

    display: flex;
    justify-content: center;

    &__serasa-link {
      display: none;
      justify-content: center;
      align-items: center;
    }

    &__lno-link {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__divider {
      display: none;
      margin-right: 1rem;
    }

    &--fixed &__divider {
      display: block;
    }

    @media ${breakpoints.desktop} {
      &__serasa-link {
        display: flex;
        padding: 0.625rem 0 0.625rem 1rem;
      }

      &__divider {
        display: block;
        align-self: center;
        margin: 0 1rem;
      }

      &__lno-img {
        height: 2rem;
        width: 7.75rem;
      }
    }
  }
`
