import css from 'styled-jsx/css'

export const styles: any = css`
  .box-cta-login {
    max-width: 26.25rem;
    background-color: #fff;
    padding: 1.5rem 1.5rem 1rem;
    border-radius: 0.625rem;
    margin: 0 auto;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.25);
  }

  .box-cta-login__cta {
    width: 100%;
    margin: 1rem 0;
    max-width: unset !important;
  }

  .box-cta-login__footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box-cta-login__footer-text {
    margin-right: 0.625rem;
  }

  .box-cta-login__serasa-logo {
    width: 4.25rem;
  }
`
