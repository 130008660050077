import {
  EventType,
  EventAction,
  EventCategory,
  EVENT_PRODUCT,
  EventEnviroment,
  EventArea,
} from '../constants'
import { dataLayerPush } from './dataLayer'
import { sanitizeText } from './sanitize'

interface ViewContentEventProps {
  category: EventCategory
  action: EventAction
  label: string
  partner?: string
  area: EventArea
}

export const viewContentEvent = ({
  category,
  action,
  label,
  partner,
  area,
}: ViewContentEventProps) => {
  const sanitizedPartner = partner && sanitizeText(partner)
  const sanitizedLabel = sanitizeText(label)
  const selectContentEventPayloadGA360 = {
    event: EventType.ViewContent,
    categoria: category,
    acao: action,
    rotulo: sanitizedLabel,
    vertical_produto: EVENT_PRODUCT,
    area,
    ambiente: EventEnviroment.LoggedOut,
    ...(sanitizedPartner && { parceiro: sanitizedPartner }),
  }
  const selectContentEventPayloadGA4 = {
    event: EventType.ViewContentGa4,
    item_text: sanitizedLabel,
    vertical_produto: EVENT_PRODUCT,
    area,
    ambiente: EventEnviroment.LoggedOut,
    ...(sanitizedPartner && { parceiro: sanitizedPartner }),
  }
  dataLayerPush(selectContentEventPayloadGA360)
  dataLayerPush(selectContentEventPayloadGA4)
}
