export const updateCssProp = (
  element: HTMLDivElement | null,
  prop: 'height' | 'maxHeight' | 'visibility',
  value: string
) => {
  if (element) {
    element.style[prop] = value
  }
}

export const removeCssProp = (
  element: HTMLDivElement | null,
  prop: 'max-height' | 'visibility'
) => {
  if (element) {
    element.style.removeProperty(prop)
  }
}

export const getScrollHeight = (element: HTMLElement | null) => {
  const scrollHeight = element?.scrollHeight

  if (!scrollHeight) {
    throw new Error('Invalid collapse child element.')
  }

  return `${scrollHeight}px`
}

export const triggerReflow = (node: HTMLElement | null) => {
  /* eslint-disable-next-line */
  node?.offsetHeight
}
