import css from 'styled-jsx/css'

export const styles: any = css`
  .spinner {
    display: none;
    border: 3px solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    vertical-align: text-bottom;

    &--visible {
      display: inline-block;
    }

    &__sr-message {
      display: none;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
