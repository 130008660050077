import css from 'styled-jsx/css'

export const styles: any = css`
  .navbar-section {
    background-color: var(--theme-primary);
    display: flex;
    width: 100%;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;
    z-index: 1020;
    padding: 0 2rem;

    &--fixed {
      z-index: 1030;
      position: fixed;
      animation-name: nav-fixed;
      animation-duration: 0.3s;
      box-shadow: rgb(0 0 0 / 10%) 0px 2px 4px 0px;
    }

    &--feirao-lno {
      background-color: var(--theme-feirao-lno);
    }

    @keyframes nav-fixed {
      from {
        transform: translateY(-3rem);
      }
      to {
        transform: translateY(0);
      }
    }
  }
`
