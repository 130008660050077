export const toKebabCase = (str: string) => {
  const strMatch =
    str &&
    str.match(
      /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
    )

  return strMatch ? strMatch.join('-').toLowerCase() : ''
}

export const removeAccents = (str: string) => {
  const accents =
    'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
  const accentsOut =
    'AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'

  const newTextLetters = str.split('').map((letter) => {
    const accentsOutIndex = accents.indexOf(letter)
    return accentsOutIndex >= 0 ? accentsOut[accentsOutIndex] : letter
  })

  return newTextLetters.join('')
}

export function hasDocument() {
  return typeof document !== 'undefined'
}

export function hasWindow() {
  return typeof window !== 'undefined'
}

export function sanitizeHTMLContent(input?: string) {
  if (!hasWindow || !hasDocument || typeof DOMParser === 'undefined' || !input)
    return

  const doc = new DOMParser().parseFromString(input, 'text/html')
  const sanitizedString = doc.body.textContent || undefined
  return sanitizedString
}
