export enum EventType {
  PageView = 'page_view',
  PageViewGa4 = 'page_view_ga4',
  EnterScreen = 'entrar_tela',
  EnterScreenGa4 = 'entrar_tela_ga4',
  SelectContent = 'select_content',
  SelectContentGa4 = 'select_content_ga4',
  Success = 'sucesso',
  SuccessGa4 = 'sucesso_ga4',
  Validation = 'validacao',
  ValidationGa4 = 'validacao_ga4',
  Error = 'erro',
  ErrorGa4 = 'erro_ga4',
  Login = 'login',
  LoginGa4 = 'login_ga4',
  ViewContent = 'view_content',
  ViewContentGa4 = 'view_content_ga4',
}

export const EVENT_PRODUCT = 'limpa-nome' as const

export enum EventEnviroment {
  LoggedIn = 'logado',
  LoggedOut = 'deslogado',
}

export enum EventArea {
  Home = 'home',
  SoftLogin = 'entrar-simplificado',
  Login = 'entrar',
  SaqueFgts = 'saque-fgts',
  Parceiros = 'parceiros',
}

export enum EventCategory {
  LnoHome = 'limpa-nome-home',
  LnoSoftLogin = 'limpa-nome-entrar-simplificado',
  LnoLogin = 'limpa-nome-login',
  LnoSaqueFgts = 'limpa-nome-saque-fgts',
  Parceiros = 'limpa-nome-parceiros',
  Error = 'erro',
}

export enum EventAction {
  ButtonClick = 'clique-botao',
  ButtonClickConsultDebts = 'clique-botao-consultar-dividas',
  ButtonClickFeirao = 'clique-botao-negocie-agora',
  LinkClick = 'clique-link',
  EnterScreen = 'entrar-tela',
  TooltipClick = 'clique-tooltip',
  CardClick = 'clique-card',
  SeloClick = 'clique-selo',
  CardImpression = 'impressao-card',
  CheckboxClick = 'clique-checkbox',
  DropdownClick = 'clique-dropdown',
  Validation = 'validacao',
  Login = 'login',
  BannerView = 'visualizacao-banner',
  BannerClick = 'clique-banner',
  BannerImpression = 'impressao-banner',
}

export enum EventContentType {
  Link = 'link',
  Menu = 'menu',
  Button = 'botao',
  ButtonOffers = 'botao-consultar-dividas',
  ButtonFeirao = 'botao-negocie-agora',
  Tooltip = 'tooltip',
  Selo = 'selo',
  Card = 'card',
  Checkbox = 'checkbox',
  Dropdown = 'dropdown',
  Banner = 'banner',
}

export const GAViewEventsPayloads = {
  viewPaymentCodeValidator: {
    category: EventCategory.LnoHome,
    action: EventAction.CardImpression,
    label: 'card-fuja-do-golpe-do-acordo',
    area: EventArea.Home,
  },
}

export const GASelectEventsPayloads = {
  commonButtonProps: {
    category: EventCategory.LnoHome,
    contentType: EventContentType.Button,
    action: EventAction.ButtonClick,
    area: EventArea.Home,
  },
  viewHeroSection: {
    event: EventType.ViewContent,
    category: EventCategory.LnoHome,
    action: EventAction.BannerImpression,
    acao: EventAction.BannerImpression,
    area: EventArea.Home,
  },
  seloFooter: {
    category: EventCategory.LnoHome,
    itemId: 'serasa-web_web_selo_rodape_04',
    contentType: EventContentType.Selo,
    action: EventAction.SeloClick,
    area: EventArea.Home,
  },
  buttonCheckOffersHero: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_botao_meio_02',
    contentType: EventContentType.ButtonOffers,
    action: EventAction.ButtonClickConsultDebts,
    area: EventArea.Home,
  },
  linkDesenrolaBrasil: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_link_meio_05',
    contentType: EventContentType.Link,
    action: EventAction.LinkClick,
    label: 'megafeirao-e-desenrola-brasil',
    area: EventArea.Home,
    itemText: 'megafeirao-e-desenrola-brasil',
  },
  makeComplain: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_link_meio_10',
    contentType: EventContentType.Link,
    action: EventAction.LinkClick,
    label: 'megafeirao-e-desenrola-clicando-aqui',
    area: EventArea.Home,
    itemText: 'megafeirao-e-desenrola-clicando-aqui',
  },
  megaFeirao: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_link_meio_04',
    contentType: EventContentType.Link,
    action: EventAction.LinkClick,
    label: 'megafeirao-serasa-e-desenrola',
    area: EventArea.Home,
    itemText: 'megafeirao-serasa-e-desenrola',
  },

  linkDesenrolaAppInstall: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_link_meio_06',
    contentType: EventContentType.Link,
    action: EventAction.LinkClick,
    label: 'megafeirao-e-desenrola-aplicativo',
    area: EventArea.Home,
    itemText: 'megafeirao-e-desenrola-aplicativo',
  },
  linkDesenrolaOffersWeb: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_link_meio_07',
    contentType: EventContentType.Link,
    action: EventAction.LinkClick,
    label: 'megafeirao-e-desenrola-site-serasa',
    area: EventArea.Home,
    itemText: 'megafeirao-e-desenrola-site-serasa',
  },
  linkPhysicalTent: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_link_meio_05',
    contentType: EventContentType.Link,
    action: EventAction.LinkClick,
    area: EventArea.Home,
  },
  buttonBenefitsSectionLearnMore: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_botao_meio_04',
    contentType: EventContentType.Button,
    action: EventAction.ButtonClick,
    label: 'saiba-mais-sao-joao',
    area: EventArea.Home,
    itemText: 'saiba-mais-sao-joao',
  },
  buttonPhysicalTentLearnMore: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_botao_meio_05',
    contentType: EventContentType.Button,
    action: EventAction.ButtonClick,
    label: 'saiba-mais-tendas-do-feirao-2023',
    area: EventArea.Home,
    itemText: 'saiba-mais-tendas-do-feirao-2023',
  },
  linkSerasa: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_botao_meio_06',
    contentType: EventContentType.Link,
    action: EventAction.LinkClick,
    label: 'serasa',
    area: EventArea.Home,
    itemText: 'serasa',
  },
  linkDownloadGooglePlay: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_link_meio_07',
    contentType: EventContentType.Link,
    action: EventAction.LinkClick,
    label: 'baixe-gratis-nosso-app-google-play',
    area: EventArea.Home,
    itemText: 'baixe-gratis-nosso-app-google-play',
  },
  linkDownloadAppStore: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_link_meio_07',
    contentType: EventContentType.Link,
    action: EventAction.LinkClick,
    label: 'baixe-gratis-nosso-app-apple-store',
    area: EventArea.Home,
    itemText: 'baixe-gratis-nosso-app-apple-store',
  },
  buttonCheckAvailableDiscounts: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_botao_meio_03',
    contentType: EventContentType.Button,
    action: EventAction.ButtonClick,
    label: 'ver-ofertas-disponiveis',
    area: EventArea.Home,
    itemText: 'ver-ofertas-disponiveis',
  },
  buttonCheckDiscounts: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_botao_meio_02',
    contentType: EventContentType.Button,
    action: EventAction.ButtonClick,
    label: 'conferir-descontos',
    area: EventArea.Home,
    itemText: 'conferir-descontos',
  },
  buttonConsultCPF: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_botao_meio_02',
    contentType: EventContentType.Button,
    action: EventAction.ButtonClick,
    label: 'consultar-cpf',
    area: EventArea.Home,
    itemText: 'consultar-cpf',
  },
  buttonCheckPaymentCode: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_botao_meio_08',
    contentType: EventContentType.Button,
    action: EventAction.ButtonClick,
    label: 'conferir-codigo-de-pagamento',
    area: EventArea.Home,
    itemText: 'conferir-codigo-de-pagamento',
  },
  buttonWhatsApp: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_botao_meio_07',
    contentType: EventContentType.Button,
    action: EventAction.ButtonClick,
    label: 'whatsapp-serasa-oficial',
    area: EventArea.Home,
    itemText: 'whatsapp-serasa-oficial',
  },
  buttonPhone: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_botao_meio_08',
    contentType: EventContentType.Button,
    action: EventAction.ButtonClick,
    label: 'telefone-serasa',
    area: EventArea.Home,
    itemText: 'telefone-serasa',
  },
  buttonCorreios: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_botao_meio_09',
    contentType: EventContentType.Button,
    action: EventAction.ButtonClick,
    label: 'saiba-mais-serasa-correios',
    area: EventArea.Home,
    itemText: 'saiba-mais-serasa-correios',
  },
  buttonMedia: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_botao_meio_10',
    contentType: EventContentType.Button,
    action: EventAction.ButtonClick,
    area: EventArea.Home,
  },
  buttonHelpCenter: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_botao_meio_12',
    contentType: EventContentType.Button,
    action: EventAction.ButtonClick,
    label: 'ir-para-central-de-ajuda',
    area: EventArea.Home,
    itemText: 'ir-para-central-de-ajuda',
  },
  buttonGetInTouch: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_botao_meio_12',
    contentType: EventContentType.Button,
    action: EventAction.ButtonClick,
    label: 'fale-com-a-gente',
    area: EventArea.Home,
    itemText: 'fale-com-a-gente',
  },
  cardFAQ: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_card_meio_11',
    contentType: EventContentType.Card,
    action: EventAction.CardClick,
    area: EventArea.Home,
  },
  buttonViewPost: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_botao_meio_13',
    contentType: EventContentType.Button,
    action: EventAction.ButtonClick,
    area: EventArea.Home,
  },
  buttonMorePost: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_botao_meio_14',
    contentType: EventContentType.Button,
    action: EventAction.ButtonClick,
    label: 'ver-mais-materias-no-blog',
    area: EventArea.Home,
    itemText: 'ver-mais-materias-no-blog',
  },
  buttonSimulateLoans: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_botao_meio_15',
    contentType: EventContentType.Button,
    action: EventAction.ButtonClick,
    label: 'simular-emprestimos',
    area: EventArea.Home,
    itemText: 'simular-emprestimos',
  },
  buttonFooterSocialMedia: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_botao_rodape_10',
    contentType: EventContentType.Button,
    action: EventAction.ButtonClick,
    area: EventArea.Home,
  },
  buttonFooterApps: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_botao_rodape_11',
    contentType: EventContentType.Button,
    action: EventAction.ButtonClick,
    area: EventArea.Home,
  },
  buttonConsultCnpj: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_botao_meio_03',
    contentType: EventContentType.Button,
    action: EventAction.ButtonClick,
    area: EventArea.Home,
  },
}
