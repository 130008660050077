import { GASelectEventsPayloads, GAViewEventsPayloads } from '../constants'
import { selectContentEvent } from '../utils'
import { viewContentEvent } from '../utils/viewContentEvent'

const consultCPFGAEvent = () => {
  selectContentEvent(GASelectEventsPayloads.buttonConsultCPF)
}

const checkDiscountsGAEvent = (label?: string) => {
  label = label || GASelectEventsPayloads.buttonCheckDiscounts.label
  const payloadWithCustomLabel = {
    ...GASelectEventsPayloads.buttonCheckDiscounts,
    label,
    itemText: label,
  }
  selectContentEvent(payloadWithCustomLabel)
}

const checkOffersHero = (label: string) => {
  const payloadWithCustomLabel = {
    ...GASelectEventsPayloads.buttonCheckOffersHero,
    label,
    itemText: label,
  }
  selectContentEvent(payloadWithCustomLabel)
}

const desenrolaBrasilEvent = () => {
  selectContentEvent(GASelectEventsPayloads.linkDesenrolaBrasil)
}
const megaFeiraoEvent = () => {
  selectContentEvent(GASelectEventsPayloads.megaFeirao)
}

const desenrolaAppInstall = () => {
  selectContentEvent(GASelectEventsPayloads.linkDesenrolaAppInstall)
}

const desenrolaOffersWeb = () => {
  selectContentEvent(GASelectEventsPayloads.linkDesenrolaOffersWeb)
}

const checkAvailableDiscounts = () => {
  selectContentEvent(GASelectEventsPayloads.buttonCheckAvailableDiscounts)
}
const makeComplain = () => {
  selectContentEvent(GASelectEventsPayloads.makeComplain)
}

const physicalTentEvent = (label: string) => {
  const payload = {
    ...GASelectEventsPayloads.linkPhysicalTent,
    label,
    itemText: label,
  }
  selectContentEvent(payload)
}

const consultCnpjEvent = (label?: string) => {
  selectContentEvent({
    ...GASelectEventsPayloads.buttonConsultCnpj,
    label: label || 'consultar-cnpj',
    itemText: label,
  })
}

const simulateLoansGAEvent = () => {
  selectContentEvent(GASelectEventsPayloads.buttonSimulateLoans)
}

const openFAQQuestionGAEvent = (title: string) => {
  const label = `perguntas-${title}-abrir`
  selectContentEvent({
    ...GASelectEventsPayloads.cardFAQ,
    label,
    itemText: label,
  })
}

const closeFAQQuestionGAEvent = (title: string) => {
  const label = `perguntas-${title}-fechar`
  selectContentEvent({
    ...GASelectEventsPayloads.cardFAQ,
    label,
    itemText: label,
  })
}

const viewBlogPostGAEvent = (title: string) => {
  const label = `ver-post-no-blog-${title}`
  selectContentEvent({
    ...GASelectEventsPayloads.buttonViewPost,
    label,
    itemText: label,
  })
}

const viewMoreBlogPostsGAEvent = () => {
  selectContentEvent(GASelectEventsPayloads.buttonMorePost)
}

const dealOptionsClickEvents = {
  serasa: GASelectEventsPayloads.linkSerasa,
  whatsApp: GASelectEventsPayloads.buttonWhatsApp,
  phone: GASelectEventsPayloads.buttonPhone,
  googlePlay: GASelectEventsPayloads.linkDownloadGooglePlay,
  appStore: GASelectEventsPayloads.linkDownloadAppStore,
  postOffice: GASelectEventsPayloads.buttonCorreios,
}

const selectDealOptionGAEvent = (
  dealOption: keyof typeof dealOptionsClickEvents
) => {
  selectContentEvent(dealOptionsClickEvents[dealOption])
}

const checkPaymentCodeGAEvent = () => {
  selectContentEvent(GASelectEventsPayloads.buttonCheckPaymentCode)
}

const goToHelpCenterGAEvent = () => {
  selectContentEvent(GASelectEventsPayloads.buttonHelpCenter)
}

const getInTouchGAEvent = () => {
  selectContentEvent(GASelectEventsPayloads.buttonGetInTouch)
}

const viewMediaGAEvent = (label: string) => {
  selectContentEvent({
    ...GASelectEventsPayloads.buttonMedia,
    label,
    itemText: label,
  })
}

const paymentCodeValidator = () => {
  viewContentEvent(GAViewEventsPayloads.viewPaymentCodeValidator)
}

const seeAllPartnersEvent = (label: string) => {
  selectContentEvent({
    ...GASelectEventsPayloads.commonButtonProps,
    label,
    itemText: label,
    itemId: 'limpa-nome_web_card_meio_03',
  })
}

export const GASelectContentEvent = {
  checkAvailableDiscounts: checkAvailableDiscounts,
  checkOffersHero: checkOffersHero,
  desenrolaBrasilEvent: desenrolaBrasilEvent,
  megaFeiraoEvent: megaFeiraoEvent,
  desenrolaAppInstall: desenrolaAppInstall,
  desenrolaOffersWeb: desenrolaOffersWeb,
  physicalTentEvent: physicalTentEvent,
  checkDiscounts: checkDiscountsGAEvent,
  consultCpf: consultCPFGAEvent,
  simulateLoans: simulateLoansGAEvent,
  openFAQQuestion: openFAQQuestionGAEvent,
  closeFAQQuestion: closeFAQQuestionGAEvent,
  viewBlogPost: viewBlogPostGAEvent,
  viewMoreBlogPosts: viewMoreBlogPostsGAEvent,
  selectDealOption: selectDealOptionGAEvent,
  checkPaymentCode: checkPaymentCodeGAEvent,
  goToHelpCenter: goToHelpCenterGAEvent,
  getInTouch: getInTouchGAEvent,
  viewMedia: viewMediaGAEvent,
  makeComplain: makeComplain,
  consultCnpjEvent: consultCnpjEvent,
  seeAllPartners: seeAllPartnersEvent,
}

export const GAViewContentEvent = {
  paymentCodeValidator: paymentCodeValidator,
}
