import { useEffect, useState } from 'react'
import clsx from 'clsx'

import { useScrollTop } from '@lno/hooks/useScrollTop'
import { useViewport } from '@lno/hooks/useViewport'
import { Viewport } from '@lno/constants/viewport'

import { NAVBAR_FIXED_OFFSET } from './constants'

import { MobileNav } from './MobileNav'
import { DesktopNav } from './DesktopNav'

import { styles } from './styles'
import { useCampaign } from '@lno/core/hooks/useCampaign'

type NavbarProps = {
  signInEnabled?: boolean
  web?: boolean
  isFeiraoLno?: boolean
}

export function Navbar({ signInEnabled, web, isFeiraoLno }: NavbarProps) {
  const [isNavFixed, setNavFixed] = useState(false)
  const { isActiveCampaign } = useCampaign()
  const [mode, setMode] = useState<Viewport | null>(null)
  const top = useScrollTop()
  const viewport = useViewport()

  useEffect(() => {
    setMode(viewport.key)
  }, [viewport])

  useEffect(() => {
    setNavFixed(top > NAVBAR_FIXED_OFFSET)
  }, [top])

  const sectionStyles = clsx('navbar-section', {
    'navbar-section--fixed': isNavFixed,
    'colors-campaign': isActiveCampaign,
    'navbar-section--feirao-lno': isFeiraoLno,
  })

  return (
    <>
      <section className={sectionStyles}>
        {mode !== Viewport.Desktop ? (
          <MobileNav
            isNavFixed={isNavFixed}
            signInEnabled={signInEnabled}
            web={web}
          />
        ) : (
          <DesktopNav
            isNavFixed={isNavFixed}
            signInEnabled={signInEnabled}
            web={web}
          />
        )}
      </section>

      <style jsx>{styles}</style>
    </>
  )
}

Navbar.defaultProps = {
  signInEnabled: true,
}
