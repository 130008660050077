import css from 'styled-jsx/css'

export const styles: any = css`
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 20rem;
    height: 3rem;
    padding: 0 0.75rem;
    border-radius: 0.625rem;
    transition: opacity 500ms;
    border: solid 1px transparent;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }

    &:disabled {
      color: var(--fonts-low);
    }

    &--raised {
      color: #fff;

      &:focus {
        outline-offset: 0.375rem;
      }
    }

    &--bold {
      font-weight: bold;
    }

    &--full-width {
      max-width: unset;
    }

    &--raised#{&}--primary {
      background-color: var(--theme-primary);
      border-color: var(--theme-primary);
    }

    &--raised#{&}--accent {
      background-color: var(--theme-accent);
      border-color: var(--theme-accent);
    }

    &--raised#{&}--secondary {
      background-color: var(--theme-secondary);
      border-color: var(--theme-secondary);
    }

    &--outlined#{&}--primary {
      color: var(--theme-primary);
      border-color: var(--theme-primary);
    }

    &--outlined#{&}--accent {
      color: var(--theme-accent);
      border-color: var(--theme-accent);
      background-color: var(--fonts-snow);
    }

    &--outlined#{&}--secondary {
      color: var(--theme-secondary);
      border-color: var(--theme-secondary);
    }

    &--ghost#{&}--primary {
      color: var(--theme-primary);
    }

    &--ghost#{&}--accent {
      color: var(--theme-accent);
    }

    &--ghost#{&}--secondary {
      color: var(--theme-secondary);
    }

    &--raised:disabled {
      background-color: var(--bg-dark-very-low);
      border-color: var(--bg-dark-very-low);
    }

    &--outlined:disabled {
      border-color: var(--bg-dark-very-low);
    }
  }
`
