import { useEffect, KeyboardEvent } from 'react'

import { isEscapeKeyEvent } from '@lno/utils/keyboard'

import useEventCallback from '../useEventCallback'

type Options = {
  enabled?: boolean
}

export function useEscapeKeyPress(handler: Function, options: Options = {}) {
  const { enabled = true } = options

  const handleKeyDown = useEventCallback(
    (event: KeyboardEvent) => {
      if (isEscapeKeyEvent(event)) {
        handler()
      }
    },
    [handler]
  )

  useEffect(() => {
    if (enabled) {
      document.addEventListener('keydown', handleKeyDown)
    } else {
      document.removeEventListener('keydown', handleKeyDown)
    }

    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [enabled, handleKeyDown])
}
