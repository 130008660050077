import { IS_CAMPAIGN_SEASON } from '@lno/core/constants'
import type { Campaign, Partners } from '@lno/core/models/Campaign'
import { LnoContent } from '@lno/core/models/LnoContent'
import { BuilderContentApiClient } from './config'
import {
  BUILDER_API_KEY_QUERY_STRING,
  CACHE_SECONDS_QUERY_STRING,
  CONTENT_API_MODELS,
  CONTENT_API_MODELS_KEYS,
  INCLUDE_REFS_QUERY_STRING,
  LIMIT_QUERY_STRING,
} from './constants'
import {
  CampaignBuilderContentApiResponse,
  LNOBuilderContentApiResponse,
  PartnersBuilderContentApiResponse,
  Response,
} from './types'

export function createNewContentApiPath(
  model: keyof typeof CONTENT_API_MODELS,
  currentDate?: number
) {
  const dateQueryString = !!currentDate
    ? `query.endDate.$gte=${currentDate}&query.startDate.$lte=${currentDate}`
    : ''
  return `/${CONTENT_API_MODELS[model].uniqueIdentifier}?${BUILDER_API_KEY_QUERY_STRING}&${CONTENT_API_MODELS[model].fieldsQueryString}&${dateQueryString}&${LIMIT_QUERY_STRING}&${CACHE_SECONDS_QUERY_STRING}&${INCLUDE_REFS_QUERY_STRING}`
}

export const getLastCampaignRequest = async (
  currentDate: number
): Response<Campaign> => {
  if (!IS_CAMPAIGN_SEASON) return null
  const requestPath = createNewContentApiPath(
    CONTENT_API_MODELS_KEYS.campaign,
    currentDate
  )
  try {
    const response =
      await BuilderContentApiClient.get<CampaignBuilderContentApiResponse>(
        requestPath
      )
    return response.data.results[0] || null
  } catch (e) {
    return null
  }
}

export const getPartnersRequest = async (
  currentDate: number
): Response<Partners> => {
  if (!IS_CAMPAIGN_SEASON) return null
  const requestPath = createNewContentApiPath(
    CONTENT_API_MODELS_KEYS.partners,
    currentDate
  )
  try {
    const response =
      await BuilderContentApiClient.get<PartnersBuilderContentApiResponse>(
        requestPath
      )
    return response.data.results[0] || null
  } catch (e) {
    return null
  }
}

const getCmsContentBasedOnCurrentPeriod = async (
  currentDate: number
): Response<LnoContent> => {
  try {
    const requestPathWithDateQuery = createNewContentApiPath(
      CONTENT_API_MODELS_KEYS.lno,
      currentDate
    )

    const response =
      await BuilderContentApiClient.get<LNOBuilderContentApiResponse>(
        requestPathWithDateQuery
      )

    return response.data.results[0] || null
  } catch (e) {
    return null
  }
}

export const getAnyPublishedCmsContent = async (): Response<LnoContent> => {
  try {
    const requestPathWithoutDateQuery = createNewContentApiPath(
      CONTENT_API_MODELS_KEYS.lno
    )

    const response =
      await BuilderContentApiClient.get<LNOBuilderContentApiResponse>(
        requestPathWithoutDateQuery
      )

    return response.data.results[0] || null
  } catch (e) {
    return null
  }
}

export const getCmsContent = async (
  currentDate: number
): Response<LnoContent> => {
  return await getCmsContentBasedOnCurrentPeriod(currentDate)
}
