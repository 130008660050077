import css from 'styled-jsx/css'

export const styles: any = css`
  .hamburger-menu {
    display: flex;
    align-items: center;

    &__button {
      display: flex;
      color: #fff;
      font-weight: 500;
      padding: 0.625rem 1rem;
      font-size: 0.625rem;
      align-items: center;
    }

    &__text {
      margin-left: 5px;
    }

    &--fixed &__text {
      display: none;
    }
  }
`
