import { dataLayerPush } from './dataLayer'
import { sanitizeText } from './sanitize'
import {
  EventCategory,
  EventContentType,
  EventAction,
  EventArea,
  EventType,
  EventEnviroment,
  EVENT_PRODUCT,
} from '../constants'

export type SelectContentEventPayload = {
  category: EventCategory
  itemId: string
  contentType: EventContentType | string
  action: EventAction | string
  label: string
  area: EventArea
  itemText?: string
  partner?: string
}

export const selectContentEvent = (payload: SelectContentEventPayload) => {
  const {
    category,
    itemId,
    contentType,
    action,
    label,
    area,
    partner,
    itemText = null,
  } = payload

  const finalItemText = sanitizeText(itemText || label)
  const finalLabel = sanitizeText(label)

  dataLayerPush({
    event: EventType.SelectContent,
    categoria: category,
    acao: action,
    rotulo: finalLabel,
    vertical_produto: EVENT_PRODUCT,
    area,
    ambiente: EventEnviroment.LoggedOut,
    parceiro: partner,
  })

  dataLayerPush({
    event: EventType.SelectContentGa4,
    item_id: itemId,
    content_type: contentType as EventContentType,
    item_text: finalItemText,
    vertical_produto: EVENT_PRODUCT,
    area,
    ambiente: EventEnviroment.LoggedOut,
    parceiro: partner,
  })
}
