import css from 'styled-jsx/css'

import { breakpoints, typography } from '@lno/constants'

export const styles: any = css`
  .typo {
    &--italic {
      font-style: italic;
    }

    &--underline {
      text-decoration: underline;
    }

    &--left {
      text-align: left;
    }

    &--center {
      text-align: center;
    }

    &--right {
      text-align: right;
    }

    &--high {
      color: var(--fonts-high);
    }

    &--medium {
      color: var(--fonts-medium);
    }

    &--low {
      color: var(--fonts-low);
    }

    &--snow-high {
      color: var(--fonts-snow-high);
    }

    &--snow {
      color: var(--fonts-snow);
    }

    &--accent {
      color: var(--fonts-accent);
    }

    &--caption {
      ${typography.caption()}
    }

    &--bodyP {
      ${typography.bodyP()}
    }

    &--bodyM {
      ${typography.bodyM()}
    }

    &--subheading {
      ${typography.subheading()}
    }

    &--headingXS {
      ${typography.headingXSMobile()}

      @media ${breakpoints.desktop} {
        ${typography.headingXSDesktop()}
      }
    }

    &--headingS {
      ${typography.headingSMobile()}

      @media ${breakpoints.desktop} {
        ${typography.headingSDesktop()}
      }
    }

    &--headingM {
      ${typography.headingMMobile()}

      @media ${breakpoints.desktop} {
        ${typography.headingMDesktop()}
      }
    }

    &--headingL {
      ${typography.headingLMobile()}

      @media ${breakpoints.desktop} {
        ${typography.headingLDesktop()}
      }
    }

    &--display {
      ${typography.displayMobile()}

      @media ${breakpoints.desktop} {
        ${typography.displayDesktop()}
      }
    }

    &--bold {
      font-weight: bold;
    }

    &--normal {
      font-weight: normal;
    }
  }
`
