import clsx from 'clsx'

import { LN_WEBFILES_COMMON_PRODUCTS_URL } from '@lno/constants'

import { Divider } from '../Divider'

import { styles } from './styles'
import { VisuallyHidden } from '../../VisuallyHidden'

type BrandProps = {
  isNavFixed: boolean
  className?: string
  web?: boolean
}

const jsxScope = `jsx-${styles.__hash}`

export function Brand({ isNavFixed, className, web }: BrandProps) {
  const homeUrl = web ? '/limpa-nome-online/web' : '/limpa-nome-online/'
  const containerStyles = clsx(className, 'nav-brand', {
    'nav-brand--fixed': isNavFixed,
  })

  return (
    <>
      <div className={containerStyles}>
        <a href="/" className="nav-brand__serasa-link">
          <img
            src={`${LN_WEBFILES_COMMON_PRODUCTS_URL}/square/serasa-white.svg`}
            alt="Logo da Serasa"
            width="22"
            height="28"
            className="nav-brand__serasa-img"
            aria-hidden
          />
          <VisuallyHidden>Ir para a página principal da Serasa</VisuallyHidden>
        </a>

        <Divider className={clsx(jsxScope, 'nav-brand__divider')} />

        <a href={homeUrl} className="nav-brand__lno-link">
          <img
            src={`${LN_WEBFILES_COMMON_PRODUCTS_URL}/limpa-nome-white.svg`}
            alt="Logo do Serasa Limpa Nome"
            height="24"
            width="92"
            className="nav-brand__lno-img"
            aria-hidden
          />
          <VisuallyHidden>
            Ir para a página principal do Serasa Limpa Nome
          </VisuallyHidden>
        </a>
      </div>

      <style jsx>{styles}</style>
    </>
  )
}

export default Brand
