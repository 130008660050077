import css from 'styled-jsx/css'

import arrowIcon from '@assets/images/chevron-bottom.svg'

export const globalStyles = css.global`
  strong {
    font-weight: bold;
  }
  a {
    color: var(--theme-primary);
  }
  ul {
    margin-left: 1.25rem;
  }
`

export const styles: any = css`
  .collapsible-section {
    margin-bottom: 0.5rem;
  }

  .collapsible-section:last-child {
    margin-bottom: 0;
  }

  .collapsible-section__toggle {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1.438rem 2.125rem;
    margin: 0;
    position: relative;
    background-color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px #ececec;
    color: var(--fonts-high);
    font-size: 1rem;
    line-height: 1.5rem;
    cursor: pointer;
    text-align: left;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  .collapsible-section__toggle::before {
    content: '';
    position: absolute;
    right: 0.5rem;
    top: calc(50% - 1rem);
    width: 2rem;
    height: 2rem;
    background-image: url(${arrowIcon});
    background-position: 50%;
    background-size: 14px auto;
    background-repeat: no-repeat;
    transition: transform 0.2s ease-out;
  }

  .collapsible-section__toggle--open {
    position: relative;
  }

  .collapsible-section__toggle--open::before {
    content: '';
    transform: rotate(-180deg);
  }

  .collapsible-section__toggle--bold {
    font-weight: bold;
  }

  .collapsible-section__wrapper {
    background-color: #f5f8fa;
    width: 95%;
    margin: 0 auto;
    padding: 1.5rem 1.875rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    opacity: 0.8;
  }
`
