import { useEffect, useState, useCallback } from 'react'

import { throttle } from '@lno/core/utils/throttle'
import { addPassiveEventListener } from '@lno/utils/passiveEvent'

type UseScrollTopParams = {
  timeout?: number
}

export const useScrollTop = (options: UseScrollTopParams = {}) => {
  const { timeout } = options
  const [top, setTop] = useState(0)

  const handleScroll = useCallback((event) => {
    if (!event.target) {
      return
    }
    setTop(event.target.documentElement.scrollTop)
  }, [])

  useEffect(() => {
    const delayedScrollHandler = throttle(handleScroll, timeout)

    addPassiveEventListener(window, 'scroll', delayedScrollHandler)

    return function cleanup() {
      window.removeEventListener('scroll', delayedScrollHandler)
    }
  }, [handleScroll, timeout])

  return top
}
