import css from 'styled-jsx/css'

export const styles: any = css`
  .profile-menu {
    $root: &;
    display: flex;
    justify-content: flex-end;

    &__avatar-link {
      display: flex;
      align-items: center;
      padding: 0 1rem;
    }

    &__button {
      display: none;
      background-color: #fff;
      color: var(--theme-primary);
      text-decoration: none;
      box-shadow: rgb(18 37 79 / 30%) 0px 2px 2px 0px;
      font-size: 0.625rem;
      margin-right: 0.5rem;
      border-radius: 2px;
      border: 0;
      width: 4.3125rem;
      height: 1.9375rem;
      justify-content: center;
      align-items: center;
    }

    &--fixed {
      margin-left: auto;

      #{$root}__avatar-link {
        display: none;
      }

      #{$root}__button {
        display: flex;
      }
    }
  }
`
