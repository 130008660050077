import { dataLayerPush } from './dataLayer'

import {
  EVENT_PRODUCT,
  EventAction,
  EventArea,
  EventCategory,
  EventEnviroment,
  EventType,
} from '../constants'

export type PageViewEventPayload = {
  title: string
  path: string
  area: EventArea
  label: string
  category: EventCategory
  itemText?: string
  partner?: string
}

export const pageViewEvent = (payload: PageViewEventPayload) => {
  const {
    title,
    path,
    area,
    label,
    category,
    partner,
    itemText = null,
  } = payload

  const url = window.location.href
  const finalItemText = itemText || label
  const typeProject = 'lno-frontend-concierge'

  dataLayerPush({
    event: EventType.PageView,
    page_location: url,
    page_title: title,
    tipo_projeto: typeProject,
    page_path: path,
    vertical_produto: EVENT_PRODUCT,
    area,
    ambiente: EventEnviroment.LoggedOut,
    parceiro: partner,
  })

  dataLayerPush({
    event: EventType.EnterScreen,
    categoria: category,
    acao: EventAction.EnterScreen,
    rotulo: label,
    vertical_produto: EVENT_PRODUCT,
    area,
    ambiente: EventEnviroment.LoggedOut,
    parceiro: partner,
  })

  dataLayerPush({
    event: EventType.PageViewGa4,
    page_location: url,
    page_title: title,
    page_path: path,
    vertical_produto: EVENT_PRODUCT,
    area,
    ambiente: EventEnviroment.LoggedOut,
    parceiro: partner,
    tipo_projeto: typeProject,
  })

  dataLayerPush({
    event: EventType.EnterScreenGa4,
    item_text: finalItemText,
    vertical_produto: EVENT_PRODUCT,
    area,
    ambiente: EventEnviroment.LoggedOut,
    parceiro: partner,
  })
}
