import { Link } from '@lno/components/Link'
import { useCampaign } from '@lno/core/hooks/useCampaign'

import { FooterLinkStruct } from '../types'

type FooterLinkProps = {
  className?: string
  link: FooterLinkStruct
  onClick?: () => void
}

type IconProps = {
  link: FooterLinkStruct
}

const Icon = ({ link }: IconProps) => {
  if (!link.img) return <>{link.title}</>

  if (!link.img.srcWebp) return <NormalImage {...link.img} />

  return <ImageWithWebp {...link.img} />
}

export function FooterLink({ link, className, onClick }: FooterLinkProps) {
  return (
    <Link
      className={className}
      href={link.href}
      data-gtm-type="click"
      data-gtm-clicktype="rodape"
      data-gtm-name={link.name}
      external={link.external}
      onClick={onClick}
      aria-label={link.img && link.img.alt}
    >
      <Icon link={link} />
    </Link>
  )
}

FooterLink.defaultProps = {}

export default FooterLink

interface NormalImage {
  src: string
  srcPink?: string
  alt: string
  width: number
  height: number
}

function NormalImage(props: NormalImage) {
  const { isActiveCampaign } = useCampaign()
  const sourceUrl =
    isActiveCampaign && props.srcPink ? props.srcPink : props.src
  return (
    <img
      src={sourceUrl}
      alt=""
      width={props.width}
      height={props.height}
      loading="lazy"
    />
  )
}

interface ImageWithWebpProps extends NormalImage {
  srcWebp?: string
}

function ImageWithWebp(props: ImageWithWebpProps) {
  return (
    <picture aria-hidden>
      <source srcSet={`${props.srcWebp} 1x`} type="image/webp" />
      <img
        src={props.src}
        loading="lazy"
        alt=""
        width={props.width}
        height={props.height}
      />
    </picture>
  )
}
