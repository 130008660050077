import clsx from 'clsx'

import { HamburgerMenu } from '../Hamburger'
import { Brand } from '../Brand'
import { ProfileMenu } from '../ProfileMenu'

import { styles } from './styles'
import { AnchorTestButton } from '../AnchorTestButton'

const jsxScope = `jsx-${styles.__hash}`

type MobileNavProps = {
  isNavFixed: boolean
  signInEnabled?: boolean
  web?: boolean
}

export function MobileNav({ isNavFixed, signInEnabled, web }: MobileNavProps) {
  const navbarStyles = clsx('navbar', {
    'navbar--fixed': isNavFixed,
  })

  return (
    <>
      <header className={navbarStyles}>
        <HamburgerMenu
          isNavFixed={isNavFixed}
          className={jsxScope}
          signInEnabled={signInEnabled}
        />
        <Brand isNavFixed={isNavFixed} className={jsxScope} web={web} />

        {isNavFixed && (
          <AnchorTestButton
            showIcon={false}
            size="small"
            className="anchor__button-header"
          />
        )}

        {signInEnabled && (
          <ProfileMenu isNavFixed={isNavFixed} className={jsxScope} />
        )}
      </header>

      <style jsx>{styles}</style>
    </>
  )
}
