import css from 'styled-jsx/css'

export const styles: any = css`
  .popup-menu {
    position: fixed;
    background-color: #fff;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: none;
    z-index: 2;
    padding: 0px 1rem 1.375rem;
    overflow-y: auto;

    &--open {
      display: block;
    }

    &__close-button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0.625rem 1rem;
      font-weight: 500;
      font-size: 0.625rem;
      color: var(--theme-primary);
      margin: 7px 0 0 -1rem;
    }

    &__close-icon {
      margin-right: 5px;
    }

    &__button-wrapper {
      display: flex;
      margin: 2.25rem 0 0;
      justify-content: center;

      & > a + a {
        margin-left: 0.625rem;
      }
    }

    &__list {
      margin: 1.5rem 0 2rem;
    }

    &__link {
      display: flex;
      text-decoration: none;
      justify-content: space-between;
      align-items: center;
      padding: 0px 1rem;
      font-size: 0.875rem;
      border-bottom: 1px solid #e0e0e0;
      height: 3.4375rem;
      color: #666;

      &:first-child {
        border-top: 1px solid #e0e0e0;
      }
    }
  }
`
