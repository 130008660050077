import { DetailedHTMLProps, AnchorHTMLAttributes } from 'react'
import { VisuallyHidden } from '../VisuallyHidden'

import { getLinkProps } from './utils'

type LinkProps = {
  external?: boolean
} & DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>

export function Link(props: LinkProps) {
  const { children, external, target, ...otherProps } = props

  const { message, ...linkProps } = getLinkProps({ external, target })

  return (
    <a {...otherProps} {...linkProps}>
      {children}
      {message && <VisuallyHidden>{message}</VisuallyHidden>}
    </a>
  )
}

Link.defaultProps = {
  target: undefined,
  external: false,
}

export default Link
