export const BUILDER_CONTENT_API_BASE_URL = `${process.env.NEXT_PUBLIC_BUILDER_CONTENT_API_HOST}/api/v3/content`
export const BUILDER_API_KEY_QUERY_STRING = `apiKey=${process.env.NEXT_PUBLIC_BUILDER_PUBLIC_KEY}`

const BUILDER_LNO_MODEL = process.env.BUILDER_LNO_MODEL
const BUILDER_PARTNERS_MODEL = process.env.BUILDER_PARTNERS_MODEL
const BUILDER_CAMPAIGN_MODEL = process.env.BUILDER_CAMPAIGN_MODEL

const LNO_QUERY_STRING = 'fields=id,endDate,startDate,data'
const CAMPAIGN_FIELDS_QUERY_STRING =
  'fields=id,endDate,startDate,data.hero,data.faq,data.stage,data.ogImage'
const PARTNERS_FIELDS_QUERY_STRING =
  'fields=id,endDate,startDate,data.tierOne,data.tierTwo,data.tierThree,data.others,data.label,data.callToAction'

export const LIMIT_QUERY_STRING = 'limit=1'
export const CACHE_SECONDS_QUERY_STRING = 'cacheSeconds=30'
export const INCLUDE_REFS_QUERY_STRING = 'includeRefs=true'

export const CONTENT_API_MODELS_KEYS = {
  lno: 'lno',
  campaign: 'campaign',
  partners: 'partners',
}

export const CONTENT_API_MODELS = {
  [CONTENT_API_MODELS_KEYS.lno]: {
    uniqueIdentifier: BUILDER_LNO_MODEL,
    fieldsQueryString: LNO_QUERY_STRING,
  },
  [CONTENT_API_MODELS_KEYS.campaign]: {
    uniqueIdentifier: BUILDER_CAMPAIGN_MODEL,
    fieldsQueryString: CAMPAIGN_FIELDS_QUERY_STRING,
  },
  [CONTENT_API_MODELS_KEYS.partners]: {
    uniqueIdentifier: BUILDER_PARTNERS_MODEL,
    fieldsQueryString: PARTNERS_FIELDS_QUERY_STRING,
  },
}
