import { useState, useEffect } from 'react'

import clsx from 'clsx'

import { toggleScrollLock } from '@lno/utils/scrollLock'

import { PopUpMenu } from '../PopUpMenu'

import { hamburgerImg } from '../images'

import { styles } from './styles'

type HamburgerMenuProps = {
  isNavFixed: boolean
  signInEnabled?: boolean
  className: string
}

export function HamburgerMenu(props: HamburgerMenuProps) {
  const { isNavFixed, signInEnabled, className } = props
  const [open, setOpen] = useState(false)

  const containerStyles = clsx(className, 'hamburger-menu', {
    'hamburger-menu--fixed': isNavFixed,
  })

  const handleToggle = () => {
    setOpen((prevState) => !prevState)
  }

  useEffect(() => {
    toggleScrollLock(open)
  }, [open])

  return (
    <>
      <div className={containerStyles}>
        <button
          className="hamburger-menu__button"
          onClick={handleToggle}
          aria-labelledby="hamburger-menu__text"
        >
          <img src={hamburgerImg} alt="" width="12" height="14" />
          <span id="hamburger-menu__text" className="hamburger-menu__text">
            MENU
          </span>
        </button>

        <PopUpMenu
          open={open}
          signInEnabled={signInEnabled}
          onClose={handleToggle}
        />
      </div>

      <style jsx>{styles}</style>
    </>
  )
}

HamburgerMenu.defaultProps = {}

export default HamburgerMenu
